import useSubscriptions from './useSubscriptions';
import { useLocation } from 'react-router';

import HeaderBanner from '../classes/Membership/HeaderBanner';
import { EAddons, EApplyQuizState, ILooseObject } from '../types';
import DashboardBanner from '../classes/Membership/DashboardBanner';
import BaseLineTestLayoutComponents from '../classes/Membership/BaseLineTestLayoutComponents';
import QuizYourself from '../classes/Membership/QuizYourself';
import StudySpaceTitle from '../classes/Membership/StudySpaceTitle';
import SideBarStyled from '../classes/Membership/SideBarStyled';
import ManualStartQuizComponents from '../classes/Membership/ManualStartQuizComponents';
import ContinueQuizButtons from '../classes/Membership/ContinueQuiz';
import BaseLineTestReviewResults from '../classes/Membership/BaseLineTestReviewResults';
import ReviewResultsAnalysisTitle from '../classes/Membership/ReviewResultsAnalysisTitle';
import useAttempts from './useAttempts';
import useAuthorization from './useAuthorization';

const classList = {
  reviewResults: { class: BaseLineTestReviewResults, name: 'BaseLineTestReviewResults' },
  headerBanner: { class: HeaderBanner, name: 'HeaderBanner' },
  dashboardBanner: { class: DashboardBanner, name: 'DashboardBanner' },
  baseLineTestLayoutComponents: { class: BaseLineTestLayoutComponents, name: 'BaseLineTestLayoutComponents' },
  quizYourself: { class: QuizYourself, name: 'QuizYourself' },
  studySpaceTitle: { class: StudySpaceTitle, name: 'StudySpaceTitle' },
  sideBarStyled: { class: SideBarStyled, name: 'SideBarStyled' },
  manualStartQuizComponents: { class: ManualStartQuizComponents, name: 'manualStartQuizComponents' },
  continueQuizButtons: { class: ContinueQuizButtons, name: 'ContinueQuizButtons' },
  reviewResultsAnalysisTitle: { class: ReviewResultsAnalysisTitle, name: 'ReviewResultsAnalysisTitle' },
};
const classNames = Object.values(classList).map(currentClass => currentClass.name);
type TClasses = typeof classNames[number];

type TContextMap = {
  [key in TClasses]: ILooseObject;
};

type TReturn = {
  reviewResults: BaseLineTestReviewResults;
  headerBanner: HeaderBanner;
  dashboardBanner: DashboardBanner;
  baseLineTestLayoutComponents: BaseLineTestLayoutComponents;
  quizYourself: QuizYourself;
  studySpaceTitle: StudySpaceTitle;
  sideBarStyled: SideBarStyled;
  manualStartQuizComponents: ManualStartQuizComponents;
  continueQuizButtons: ContinueQuizButtons;
  reviewResultsAnalysisTitle: ReviewResultsAnalysisTitle;
};

interface IUseMembershipProxy {
  contextMap?: Partial<TContextMap>;
  useStrict?: boolean;
  requiredAddons?: EAddons[];
}

const useMembershipProxy = (props?: IUseMembershipProxy) => {
  const { contextMap, useStrict = false, requiredAddons } = props || {};
  const {
    isSubscribed,
    isSubscribedStrict,
    isTrialling,
    isFreeUserStrict,
    addons,
    isDeprecatedSubscription,
    isPaymentIssue,
    isSponsored,
  } = useSubscriptions({
    useFetchSubscriptions: false,
  });

  const { applyQuizState } = useAttempts();
  const { pathname: location } = useLocation();
  const { state } = useAuthorization();

  const classInitializations = Object.entries(classList).reduce((accum: ILooseObject, pair) => {
    const [key, currentClass] = pair;
    const className = currentClass.name;

    const initialization = new currentClass.class({
      isSubscribed,
      location,
      context: contextMap?.[className],
      useStrict,
      isSubscribedStrict,
      isTrialling,
      isFreeUserStrict,
      addons,
      requiredAddons,
      isDeprecatedSubscription: !!isDeprecatedSubscription,
      isPaymentIssue,
      isSponsored,
      applyQuizState: applyQuizState || EApplyQuizState.ENHANCE_DONE,
      role: state.storedRole,
    });
    accum[key] = initialization;
    return accum;
  }, {}) as TReturn;

  return { ...classInitializations };
};

export default useMembershipProxy;
