import { IStepComponent } from '../types';

import { ExitCardsContainer, FAQContainer, Title } from './styled';
import { en } from '../../../i18n';
import parse from 'html-react-parser';
import Button from '../../components/Button';
import { useDispatch } from '../../hooks';
import { actions } from '../redux/slice';
import { EApplyQuizState, EApplyTypes } from '../../types';
import { ReactComponent as PurpleCheckMark } from '../../../images/icons/apply/purpleCheckMark.svg';
import { ReactComponent as GreyCheckMark } from '../../../images/icons/apply/greyCheckMark.svg';
import ExitQuizCard from '../ExitQuizCard';
import useStartApplyEnhance from '../useStartApplyEnhance';

interface IExit extends IStepComponent {
  supported: boolean;
}

const { apply } = en;
const { exit } = apply;
const { notSupported, supported: supportedText } = exit;

const Exit = ({ supported }: IExit) => {
  const { dispatch } = useDispatch();

  const { applyQuizState, renderEnhanceButton } = useStartApplyEnhance();

  const reset = () => {
    dispatch(actions.setCurrentType({ type: EApplyTypes.BASE }));
    dispatch(actions.setCurrentQuestionIndex({ index: 0 }));
  };

  const renderTitles = () => {
    if (!supported) {
      return (
        <>
          <Title applyBottomMargin normalFontWeight fontSize="1.25rem">
            {parse(notSupported.title)}
          </Title>
          <Title applyBottomMargin fontSize="1.25rem">
            {notSupported.subTitle}
          </Title>
        </>
      );
    }

    return (
      <>
        <Title applyBottomMargin normalFontWeight fontSize="1.25rem">
          {parse(supportedText.title)}
        </Title>
        <Title applyBottomMargin>{supportedText.subTitle}</Title>
      </>
    );
  };

  const renderApplyQuizButton = () => (
    <Button size="small" onClick={reset} variant="underlinedSpan">
      {exit.resetText}
    </Button>
  );

  const renderEnhanceProfileIcon = () => {
    if (applyQuizState === EApplyQuizState.ENHANCE_DONE) return PurpleCheckMark;
    return GreyCheckMark;
  };

  return (
    <>
      {renderTitles()}

      <ExitCardsContainer>
        <ExitQuizCard
          Icon={renderEnhanceProfileIcon()}
          title={exit.enhanceProfileTitle}
          subTitle={exit.enhancePorfileSubTitle}
          action={renderEnhanceButton()}
        />
        <ExitQuizCard
          Icon={PurpleCheckMark}
          title={exit.applyQuizTitle}
          subTitle={exit.applyQuizSubTitle}
          action={renderApplyQuizButton()}
        />
      </ExitCardsContainer>
      <FAQContainer>{parse(exit.faq)}</FAQContainer>
    </>
  );
};

export default Exit;
