import { Checkbox, Radio, useMediaQuery, useTheme } from '@mui/material';
import TemplateRenderer from '../../SmartQuiz/question/templates';
import { ILooseObject, IQuestion, TApplyResponse } from '../../types';
import { ChoiceContainerCommute, Title } from './styled';
import parse from 'html-react-parser';
import { actions, applySelector } from '../redux/slice';
import { useDispatch } from '../../hooks';
import { useSelector } from 'react-redux';
import React from 'react';

interface IUseStep {
  question: IQuestion;
  centerTitleOnMobile?: boolean;
}

const useStep = ({ question, centerTitleOnMobile }: IUseStep) => {
  const theme = useTheme();
  const { dispatch } = useDispatch();
  const { questionAnswers } = useSelector(applySelector.allState);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const title = question && (
    <Title centerMobile={centerTitleOnMobile}>
      <TemplateRenderer content={question.content.question} />
    </Title>
  );

  const getDropdownOptions = () => {
    const choices = question.content?.choices;
    if (!choices) return [];
    return choices.map((choice, index) => ({
      id: choice.id,
      label: choice.content.choice?.content.text || '',
      value: choice.id,
    }));
  };
  const currentResponse = questionAnswers[question?.id]?.response;

  const onChangeAnswer = (response: TApplyResponse) => {
    dispatch(actions.setQuestionAnswer({ questionId: question.id, response }));
  };

  const renderSanitizedText = (text?: string, mobileParse = false) => {
    if (!text) return null;
    if (isMobile) {
      if (mobileParse) {
        const santizedText = text?.replace('<br/>', ' ');
        return parse(santizedText);
      }
      const santizedText = text?.replace('<br/>', ' ').replace('<div>', '').replace('</div>', '');
      return santizedText;
    }
    return parse(text);
  };

  const renderMultipleChoices = (answerType: 'radio' | 'checkbox', maxChoices: number | null = null) => {
    if (!question.content.choices) return null;
    return question.content.choices.map((choice, index) => {
      const changeRadioBox = (e: React.SyntheticEvent) => {
        const nextResponse = currentResponse ? { ...(currentResponse as ILooseObject) } : {};
        const currentlyChecked = nextResponse[choice.id];
        if (currentlyChecked) return;

        const checked = !nextResponse[choice.id];
        nextResponse[choice.id] = checked;
        if (checked) {
          Object.keys(nextResponse).forEach(key => {
            if (key === choice.id) return;
            nextResponse[key] = false;
          });
        }
        onChangeAnswer(nextResponse);
      };

      const changeCheckBox = () => {
        let nextResponse = currentResponse ? [...(currentResponse as TApplyResponse[])] : [];
        const checked = !nextResponse.includes(choice.id);
        if (checked) {
          if (maxChoices && nextResponse.length >= maxChoices) nextResponse.shift();

          nextResponse = [...nextResponse, choice.id];
        } else {
          const index = nextResponse.indexOf(choice.id);
          if (index >= 0) {
            nextResponse.splice(index, 1);
          }
        }

        onChangeAnswer(nextResponse);
      };
      if (choice.content.choice)
        return (
          <ChoiceContainerCommute onClick={answerType === 'radio' ? changeRadioBox : changeCheckBox} className="choice">
            {answerType === 'radio' ? (
              <Radio
                key={index}
                // onChange={changeRadioBox}
                checked={(currentResponse as ILooseObject)?.[choice.id] || false}
              />
            ) : (
              <Checkbox
                key={index}
                // onChange={changeCheckBox}
                checked={Array.isArray(currentResponse) && currentResponse.includes(choice.id)}
              />
            )}
            <TemplateRenderer content={choice.content.choice} />
          </ChoiceContainerCommute>
        );
      return null;
    });
  };

  const onUpload = (file: File | null) => {
    onChangeAnswer(file);
  };

  return {
    title,
    getDropdownOptions,
    renderSanitizedText,
    renderMultipleChoices,
    onChangeAnswer,
    currentResponse,
    onUpload,
  };
};

export default useStep;
