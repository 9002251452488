import { MutableRefObject, Dispatch, SetStateAction } from 'react';
import UnstartedChat from './UnstartedChat';
import TypedResponse from './TypedResponse';
import Spinner from '../../components/Spinner';
import Message from './Message';
import { ILooseObject, IMessage } from '../../types';
import { MessagesContainer, MessagesWrapper, InfiniteLoader } from '../styled';

const ChatBody = ({
  isUnstarted,
  messagesWrapperRef,
  handleMessagesScroll,
  currentSession,
  chat,
  loadingResponse,
  typedResponse,
  typedResponseRef,
  svgLoader,
  copyToolTipText,
  setCopyTooltipText,
  isUnstartedLoading,
  prompts,
  handleExampleClick,
  userIconLetter,
  hideExamples,
}: {
  isUnstarted: boolean;
  messagesWrapperRef: MutableRefObject<HTMLDivElement>;
  handleMessagesScroll?: (e: any) => void;
  currentSession?: ILooseObject;
  chat: any;
  loadingResponse: boolean;
  typedResponse: string;
  typedResponseRef: MutableRefObject<HTMLDivElement>;
  svgLoader: string;
  copyToolTipText: string;
  setCopyTooltipText: Dispatch<SetStateAction<string>>;
  isUnstartedLoading?: boolean;
  prompts: (string | undefined)[];
  handleExampleClick: (prompt: string) => void;
  userIconLetter?: string;
  hideExamples?: boolean;
}) => {
  return !isUnstarted ? (
    <MessagesWrapper ref={messagesWrapperRef} onScroll={handleMessagesScroll}>
      <MessagesContainer>
        {currentSession?.loadingSession && chat.length === 0 ? (
          <Spinner loading={currentSession.loadingSession} />
        ) : (
          <>
            {currentSession?.loadingSession && (
              <InfiniteLoader>
                <Spinner loading={currentSession.loadingSession} inline size="20px" />
              </InfiniteLoader>
            )}
            {chat
              .filter((message: IMessage) => message.content)
              .map((message: IMessage, index: number) => (
                <Message
                  message={message}
                  index={index}
                  userIconLetter={userIconLetter || ''}
                  chat={chat}
                  copyToolTipText={copyToolTipText}
                  setCopyTooltipText={setCopyTooltipText}
                />
              ))}
            {
              <TypedResponse
                loadingResponse={loadingResponse}
                currentSession={currentSession}
                typedResponseRef={typedResponseRef}
                typedResponse={typedResponse}
                svgLoader={svgLoader}
              />
            }
          </>
        )}
      </MessagesContainer>
    </MessagesWrapper>
  ) : (
    <UnstartedChat
      isUnstartedLoading={Boolean(isUnstartedLoading)}
      prompts={prompts}
      handleExampleClick={handleExampleClick}
      hideExamples={hideExamples}
    />
  );
};

export default ChatBody;
