import { useState, useRef, ChangeEvent, MutableRefObject, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isJSONObject } from '../../utils';
import useNavigations from '../useNavigations';
import { useDispatch, useSocket } from '..';
import { actions, nurseGPTSelectors } from '../../NurseGPT/redux/slice';
import { endpoints } from '../../constants';
import NurseGPTLoader from '../../../images/nurseGPTLoader.svg';
import { BugSnag } from '../../classes';
import { en } from '../../../i18n';
import { ReadyState } from 'react-use-websocket';
import LocalStorageWithTTL from '../../classes/LocalStorageWithTTL';

enum EReconnectStatus {
  DISCONNECTED,
  PENDING_RECONNECT,
  NORMAL,
}

const RECONNECT_DIM_TIMER = 500;

const useCommonNurseGPT = ({
  socketUrl,
  useSocketAuthorization,
  useSocketWebSessionAuthorization,
}: {
  socketUrl: string;
  useSocketAuthorization: boolean;
  useSocketWebSessionAuthorization: boolean;
}) => {
  const [reconnectStatus, setReconnectStatus] = useState<EReconnectStatus>(EReconnectStatus.NORMAL);

  const { sendJsonMessage, lastMessage, readyState, initialConnection } = useSocket({
    url: socketUrl,
    useAuthorization: useSocketAuthorization,
    useWebSessionAuthorization: useSocketWebSessionAuthorization,
    setReconnectStatus,
  });
  const [prompt, setPrompt] = useState('');
  const [lastPromptAttempt, setLastPromptAttempt] = useState('');

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [typedResponse, setTypedResponse] = useState('');
  const [svgLoader, setSvgLoader] = useState('');
  const [copyToolTipText, setCopyTooltipText] = useState(en.nurseGPT.copy);
  const typedResponseRef = useRef() as MutableRefObject<HTMLDivElement>;
  const inputRef = useRef() as MutableRefObject<HTMLTextAreaElement>;
  const messagesWrapperRef = useRef() as MutableRefObject<HTMLDivElement>;
  const { refresh } = useNavigations();
  const { dispatch } = useDispatch();
  const { loadingResponse, currentSessionId, sessions, errors } = useSelector(nurseGPTSelectors.allState);
  const currentSession = sessions.find(session => session.sessionId === currentSessionId);
  const chat = currentSession?.messages || [];
  const isUnstarted = chat.length === 0 && !currentSessionId;
  const typedResponseHeight = typedResponseRef.current?.offsetHeight / 24;
  const isReconnecting = initialConnection && reconnectStatus === EReconnectStatus.PENDING_RECONNECT;

  const [hasBeenReconnecting, setHasBeenReconnecting] = useState(false);
  const [reconnectTimer, setReconnectTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isReconnecting) {
      if (!reconnectTimer) {
        const timer = setTimeout(() => {
          setHasBeenReconnecting(true);
        }, RECONNECT_DIM_TIMER);
        setReconnectTimer(timer);
      }
    } else {
      setHasBeenReconnecting(false);
      if (reconnectTimer) {
        clearTimeout(reconnectTimer);
        setReconnectTimer(null);
      }
    }

    return () => {
      if (reconnectTimer) {
        clearTimeout(reconnectTimer);
      }
    };
  }, [isReconnecting, reconnectTimer]);

  //const isReconnecting = true;
  const handlePromptChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setPrompt(e.target.value);
  };

  const handleStartNewChat = () => {
    dispatch(actions.startNewChat());
  };

  const handleExampleClick = (prompt: string) => {
    setPrompt(prompt);
    inputRef.current?.focus();
  };

  const getPlaceholder = (
    initialPlaceholder: string,
    ongoingPlaceholder: string,
    initialMobilePlaceholder: string,
    ongoingMobilePlaceholder: string,
  ) => {
    return window.innerWidth > 900
      ? chat.length === 0
        ? initialPlaceholder
        : ongoingPlaceholder
      : chat.length === 0
      ? initialMobilePlaceholder
      : ongoingMobilePlaceholder;
  };

  useEffect(() => {
    return () => {
      dispatch(actions.resetState());
    };
  }, []);

  useEffect(() => {
    if (chat.length > 0 && chat[chat.length - 1].source === 'user')
      messagesWrapperRef.current?.scrollBy({
        behavior: window.innerWidth > 900 ? 'smooth' : 'auto',
        left: 0,
        top: messagesWrapperRef.current.scrollHeight,
      });
  }, [chat.length]);

  useEffect(() => {
    if (!messagesWrapperRef.current) return;
    const bottomReached =
      Math.abs(
        messagesWrapperRef.current.scrollHeight -
          messagesWrapperRef.current.scrollTop -
          messagesWrapperRef.current.clientHeight,
      ) < 50;
    if (typedResponseHeight > 2 && bottomReached)
      messagesWrapperRef.current?.scrollBy({
        behavior: 'auto',
        left: 0,
        top: messagesWrapperRef.current.scrollHeight,
      });
  }, [typedResponseHeight]);

  useEffect(() => {
    if (!lastMessage) return;
    let messageContent = lastMessage.data;
    if (isJSONObject(messageContent) && messageContent.replace(/\s/g,'') !== 'null') {
      if (JSON.parse(messageContent).message === 'Endpoint request timed out') return;
      else
        dispatch(
          actions.submitPromptFailure({
            errors: { webSocketError: { message: messageContent } },
          }),
        );
    } else if (messageContent.includes('<sys>')) {
      messageContent = messageContent.replace('<sys>', '');
      messageContent = messageContent.replace('</sys>', '');
      const parsedMessageContent = JSON.parse(messageContent);
      if (parsedMessageContent.statusCode !== 200) {
        dispatch(
          actions.submitPromptFailure({
            errors: { webSocketError: { message: parsedMessageContent } },
          }),
        );
      } else {
        const serverResponse = JSON.parse(parsedMessageContent.body);
        dispatch(
          actions.submitPromptSuccess({
            response: typedResponse,
            sessionId: serverResponse.session_id,
          }),
        );
        setTypedResponse('');
        setLastPromptAttempt('');
      }
    } else {
      setTypedResponse(typedResponse + messageContent);
    }
  }, [lastMessage]);

  useEffect(() => {
    fetch(NurseGPTLoader)
      .then(response => response.text())
      .then(text => setSvgLoader(text));
  }, []);

  useEffect(() => {
    if (errors?.webSocketError) {
      new BugSnag().notify(new Error(`${JSON.stringify(errors?.webSocketError)}`));
      setShowErrorModal(true);
    }
  }, [errors]);

  useEffect(() => {
    if (readyState !== ReadyState.OPEN && loadingResponse && reconnectStatus === EReconnectStatus.NORMAL) {
      console.log('Attempting Reconnect');
      setReconnectStatus(EReconnectStatus.PENDING_RECONNECT);
    }
  }, [readyState, loadingResponse]);

  useEffect(() => {
    if (reconnectStatus === EReconnectStatus.DISCONNECTED) {
      new BugSnag().notify(new Error(`Can't send or receive messages. Connection State: ${readyState}`));
      setShowErrorModal(true);
      const storage = new LocalStorageWithTTL('lastGPTPrompt');
      storage.set(lastPromptAttempt);
    }
  }, [reconnectStatus]);

  useEffect(() => {
    const storage = new LocalStorageWithTTL('lastGPTPrompt');
    const lastPrompt = storage.get();
    if (lastPrompt) {
      storage.clear();
      setPrompt(lastPrompt);
    }
  }, []);

  useEffect(() => {
    if (reconnectStatus !== EReconnectStatus.PENDING_RECONNECT || readyState !== ReadyState.OPEN) return;
    const adjustedSessionId = currentSessionId === 'TEMP_ID' ? '' : currentSessionId;

    setReconnectStatus(EReconnectStatus.NORMAL);

    if (lastPromptAttempt) {
      dispatch(
        actions.submitErrorPrompt({
          prompt: lastPromptAttempt,
          currentSessionId: adjustedSessionId,
          sendJsonMessage,
          fileUpload: null,
        }),
      );
      setLastPromptAttempt('');
    }
  }, [readyState, reconnectStatus]);

  return {
    state: {
      prompt,
      showErrorModal,
      typedResponse,
      svgLoader,
      copyToolTipText,
      typedResponseRef,
      inputRef,
      messagesWrapperRef,
      loadingResponse,
      currentSessionId,
      sessions,
      errors,
      currentSession,
      chat,
      isUnstarted,
      initialConnection,
      lastPromptAttempt,
      reconnectStatus,
      readyState,
      isReconnecting: hasBeenReconnecting,
    },
    control: {
      sendJsonMessage,
      setPrompt,
      setCopyTooltipText,
      refresh,
      dispatch,
      handlePromptChange,
      handleStartNewChat,
      handleExampleClick,
      getPlaceholder,
      setLastPromptAttempt,
      setReconnectStatus,
    },
  };
};

export { EReconnectStatus };
export default useCommonNurseGPT;
