import { IStepComponent } from '../types';
import { ChoicesContainerNursingRoles, NursingRolesContent, NursingRolesDisclaimer, NursingRolesText } from './styled';
import useStep from './useStep';
import { en } from '../../../i18n';

const NursingRoles = ({ question }: IStepComponent) => {
  const { title, renderMultipleChoices } = useStep({ question });

  return (
    <>
      {title}
      <NursingRolesDisclaimer>{en.apply.nursingCare.mobileDisclaimer}</NursingRolesDisclaimer>
      <NursingRolesContent>
        <NursingRolesText>{en.apply.nursingCare.notComfortable}</NursingRolesText>
        <ChoicesContainerNursingRoles>{renderMultipleChoices('radio')}</ChoicesContainerNursingRoles>

        <NursingRolesText>{en.apply.nursingCare.comfortable}</NursingRolesText>
      </NursingRolesContent>
    </>
  );
};

export default NursingRoles;
