import { styled } from '@mui/material/styles';
import { colors } from '../../../../themes';
import { shouldForwardProps } from '../../../utils';

interface IContainer {
  isHidden?: boolean;
}
interface IExampleDetails {
  isError?: boolean;
}

// const FullContainer = styled('div',
//    shouldForwardProp: prop => shouldForwardProps(prop, ['isHidden', 'isMounted']),
// })<IContainer>(()=>`
//   position: relative;
//   border-radius: 8px;
//   background-color: ${colors.white};
// `);

const FullContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isHidden', 'isMounted']),
})<IContainer>(
  ({ isHidden }) => `
  position: relative;
  border-radius: 8px;
  background-color: ${colors.white};
  animation: ${isHidden ? 'slideDown' : 'slideInUp'} 0.3s ease-in-out;
  
  animation-fill-mode: forwards;
  @keyframes slideDown{
  from {
   // transform: translateY(100%);
    //opacity: 1;
  }
  to {
    transform: translateY(110%);
    display:none;
    z-index:-1;
    //opacity: 0;
  }
}
  @keyframes slideInUp {
  from {
    transform: translateY(100%);
    //opacity: 0;
  }
  to {
    transform: translateY(0);
    //opacity: 1;
}
}

`,
);
const Container = styled('div')`
  height: calc(420px - 16px);
  overflow-y: scroll;

  margin-bottom: 4px;
  position: relative;

  /* Custom scrollbar for WebKit-based browsers (Chrome, Safari, Edge) */
  &::-webkit-scrollbar {
    width: 10px; /* Thin scrollbar */
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4; /* Light gray scrollbar */
    border-radius: 8px; /* Rounded corners */
  }
  &::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Lighter track background */
    border-radius: 8px;
  }

  @-moz-document url-prefix() {
    /* Firefox specific scrollbar styles */
    scrollbar-width: thin; /* Thin scrollbar */
    scrollbar-color: #c4c4c4 #f1f1f1; /* Custom thumb and track colors */
  }
`;

const SvgContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isHidden', 'isMounted']),
})<IContainer>(
  ({ isHidden }) => `

  width:100%;
  height:24px;

  border-radius:8px;
  z-index:30;
  display:flex;
  justify-content:flex-end;
  padding-top:4px;
  svg {
  margin-right:16px;
  margin-top:7px;
  cursor:pointer;
  }

`,
);

const TipsHeader = styled('div')`
  font-size: 14px;
  font-weight: 700;
  opacity: 60%;
  color: ${colors.black};
  padding-left: 8px;
`;

const SubTitle = styled('div')`
  font-size: 14px;
  color: ${colors.black};
  padding-left: 8px;
`;

const Body = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 2rem;
`;

const AccordionContainer = styled('div')`
  color: ${colors.black};
`;

const AccordionHeader = styled('div')`
  color: ${colors.black};
  font-size: 1rem;
`;

const AccordionDetailsContent = styled('div')`
  font-size: 1rem;
  color: ${colors.black};
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const AccordionDetailsTitle = styled('div')`
  font-size: 1rem;
  color: ${colors.black};
  display: flex;
  &::before {
    content: '•';

    font-size: 1rem; /* Adjust size if needed */
    margin-right: 8px; /* Space between bullet and text */
    color: black; /* Change color if desired */
    display: inline-block;
  }
`;

const ExampleContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isError']),
})<IExampleDetails>(
  ({ isError }) => `
  font-size: 0.875rem;
  margin-left: 8px;
  padding:8px;
  background:${isError ? colors.errorRed5 : colors.green15};

`,
);

const DescriptionContainer = styled(ExampleContainer)`
  background: none;
  padding: 0px;
  font-size: 1rem;
`;

const ExampleTitle = styled('div')`
  font-weight: 700;
  position: relative;
  &::after {
    content: '...';
    font-weight: bold;
    color: black; /* Customize color if needed */
  }
`;

const ExampleDetails = styled('div')`
  color: ${colors.black};
  opacity: 60%;
  position: relative; ;
`;

const DisclaimerSubText = styled('div')`
  font-size: 0.875rem;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const PositiveSamplesSeperator = styled('div')`
  margin-top: 16px;
  margin-bottom: 16px;
`;
export {
  Container,
  SvgContainer,
  TipsHeader,
  SubTitle,
  Body,
  AccordionContainer,
  AccordionHeader,
  AccordionDetailsContent,
  AccordionDetailsTitle,
  ExampleContainer,
  ExampleTitle,
  ExampleDetails,
  DisclaimerSubText,
  DescriptionContainer,
  FullContainer,
  PositiveSamplesSeperator,
};
