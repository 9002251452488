import useWebSocket, { ReadyState } from 'react-use-websocket';
import { CurrentProfile } from '../classes';
import LocalStorageWithTTL from '../classes/LocalStorageWithTTL';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { webSessionAuthSelectors } from '../webSessionAuth/redux/slice';
import { EReconnectStatus } from './NurseGPT/useCommonNurseGPT';

interface IUseWebSocket {
  url: string;
  onOpen?: () => void;
  onClose?: () => void;
  shouldReconnect?: () => boolean;
  retryOnError?: boolean;
  share?: boolean;
  useAuthorization?: boolean;
  useWebSessionAuthorization?: boolean;
  setReconnectStatus: (status: EReconnectStatus) => void;
}

const RECONNECT_INTERVAL = 5000;
const RECONNECT_TRIES = 5;

const useSocket = ({
  url,
  onOpen = () => console.log('websocket connnection opened: ', url),
  onClose = () => console.log('websocket connnection closed: ', url),
  shouldReconnect = () => true,
  retryOnError = true,
  share = true,
  useAuthorization = true,
  useWebSessionAuthorization = false,
  setReconnectStatus,
}: IUseWebSocket) => {
  const token = CurrentProfile.getToken();
  const webSessionLocalStorage = new LocalStorageWithTTL('webSession').get(false, true);
  const { webSession: webSessionRedux } = useSelector(webSessionAuthSelectors.allState);
  const [initialConnection, setInitialConnection] = useState(false);

  const webSession = webSessionLocalStorage || webSessionRedux;
  if (!webSession && useWebSessionAuthorization) {
    console.log('Websession not found');
  }

  const filterHeartbeat = (message: MessageEvent<any>) => {
    const { data } = message;
    try {
      const parsed = JSON.parse(data);
      if (parsed.message === 'Forbidden') {
        setReconnectStatus(EReconnectStatus.NORMAL);
        return false;
      }
    } catch (e) {
      return true;
    }
    return true;
  };

  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(url, {
    onOpen,
    onClose: () => {
      onClose();
      setReconnectStatus(EReconnectStatus.PENDING_RECONNECT);
    },
    shouldReconnect,
    retryOnError,
    share,
    reconnectAttempts: RECONNECT_TRIES,
    onReconnectStop: () => {
      console.log('Reconnect Failed');
      setReconnectStatus(EReconnectStatus.DISCONNECTED);
    },
    //heartbeat: useWebSessionAuthorization ? !!webSession : true,
    // heartbeat: !!webSession,
    //reconnectInterval: attemptNumber => Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
    reconnectInterval: RECONNECT_INTERVAL,
    ...(useAuthorization && { queryParams: { token } }),
    ...(useWebSessionAuthorization && { queryParams: { orbitsId: webSession } }),
    //filter: filterHeartbeat,
  });

  //console.log('Ready State:', readyState);

  useEffect(() => {
    if (!useWebSessionAuthorization || initialConnection) return;
    if (readyState === ReadyState.OPEN) setInitialConnection(true);
  }, [initialConnection, useWebSessionAuthorization, readyState]);
  return { sendJsonMessage, lastMessage, readyState, initialConnection };
};

export default useSocket;
