import { IStepComponent } from '../types';
import { Title } from './styled';
import { en } from '../../../i18n';
import useStep from './useStep';

const { apply } = en;
const SubmitEnhancement = ({ question }: IStepComponent) => {
  const { renderSanitizedText } = useStep({ question });

  return (
    <>
      <Title centerMobile>{apply.submitEnhancement.titleA}</Title>
      <Title centerMobile>{renderSanitizedText(en.apply.submitEnhancement.titleB, true)}</Title>
    </>
  );
};

export default SubmitEnhancement;
