import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import axiosRequest, { ERequestMethods } from '../../axios';
import {
  IFetchAttemptResponse,
  IAttempt,
  IStartQuizResponse,
  ILooseObject,
  IAnswerQuestionResponse,
} from '../../types';
import { endpoints } from '../../constants';
import { FullAttemptsTransformer, AllAttemptsTransformer } from '../../classes';
import { pollRequest } from '../../utils';
import { ISendQuestionResponse } from '../types';
import ClicksAnswerTransformer from '../../classes/DataTransformers/ClicksAnswerTransformer';
import { clicksQuizId } from '../../StudySpace/staticConstants';

function* attemptQuiz(action: { payload: { quizId: string; response: ISendQuestionResponse } }) {
  try {
    const { quizId, response } = action.payload;
    const { questionId } = response;
    const { data: result }: IStartQuizResponse = yield call(pollRequest, {
      url: endpoints.startQuiz,
      requestData: { body: { quizId } },
      method: ERequestMethods.POST,
    });
    const attemptId = result;
    if (!attemptId) throw new Error('Could not start quiz');
    const stoppingCriteria = (apiAttempt: any) => {
      return apiAttempt.data.attemptId === attemptId;
    };
    const { data: attempt }: IFetchAttemptResponse = yield call(
      pollRequest,
      {
        url: endpoints.fetchAttempt(attemptId),
      },
      { stopCallBack: stoppingCriteria },
    );
    const attemptsTransformer = new FullAttemptsTransformer();
    const transformedAttempt: IAttempt = yield call([attemptsTransformer, attemptsTransformer.transform], attempt);
    const allAttempsTransformer = new AllAttemptsTransformer();
    const fullTransformedAttempts: IAttempt[] = yield call(
      [allAttempsTransformer, allAttempsTransformer.transform],
      [transformedAttempt],
    );

    yield put(actions.fetchClickAttemptsSuccess({ attempts: fullTransformedAttempts }));
    yield put(actions.attemptQuizSuccess({}));
    yield put(actions.submitAnswer({ attemptId: attemptId, response }));
  } catch (e) {
    yield put(actions.attemptQuizFailure({ errors: { message: e } }));
  }
}

function* fetchClickAttempts() {
  try {
    let attempts: IAttempt[] = [];

    const { data } = yield call(axiosRequest, { url: `${endpoints.fetchAttempts}&quizId=${clicksQuizId}` });
    attempts = data.concat(attempts);
    attempts = attempts.sort((a, b) => new Date(b.ctime).getTime() - new Date(a.ctime).getTime());

    const allAttemptsTransformer = new AllAttemptsTransformer();
    const attemptsTransformer = new FullAttemptsTransformer();
    const transformedAttempts = allAttemptsTransformer.transform(attempts);
    const currentClickAttempt = transformedAttempts?.[0];

    if (!currentClickAttempt) {
      yield put(actions.fetchClickAttemptsSuccess({ attempts: [] }));
      return;
    }

    const transformedAttempt: IAttempt = yield call(
      [attemptsTransformer, attemptsTransformer.transform],
      currentClickAttempt,
    );

    const answers = new ClicksAnswerTransformer('').getRawAnswers(transformedAttempt);

    yield put(actions.setQuestionAnswers({ answers }));
    yield put(actions.fetchClickAttemptsSuccess({ attempts: [transformedAttempt] }));
  } catch (error) {
    yield put(actions.fetchClickAttemptsSuccess({ attempts: [] }));
  }
}

function* submitAnswer(action: {
  payload: {
    attemptId: string;
    response: ISendQuestionResponse;
    storedResponse?: ILooseObject;
  };
}) {
  try {
    const { payload } = action;
    const { response, storedResponse } = payload;
    const { questionId } = response;
    const questionResponseTransformer = new ClicksAnswerTransformer(questionId, storedResponse);
    const transformedResponse = questionResponseTransformer.transformOut(response);

    const { data }: IAnswerQuestionResponse = yield call(axiosRequest, {
      url: endpoints.submitAnswer(payload.attemptId),
      requestData: { body: transformedResponse },
      method: ERequestMethods.POST,
    });

    yield put(
      actions.submitAnswerSuccess({
        questionId,
        response: JSON.parse(transformedResponse.response),
      }),
    );
  } catch (error) {
    yield put(actions.submitAnswerFailure({ errors: {} }));
  }
}

function* watchAttemptQuiz() {
  yield takeLatest(actions.attemptQuiz, attemptQuiz);
}

function* watchSubmitAnswer() {
  yield takeLatest(actions.submitAnswer, submitAnswer);
}

function* watchFetchClickAttempts() {
  yield takeLatest(actions.fetchClicksAttempts, fetchClickAttempts);
}

const sagas = [watchAttemptQuiz, watchSubmitAnswer, watchFetchClickAttempts];

export default sagas;
