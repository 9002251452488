import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import LocalStorageWithTTL from '../../classes/LocalStorageWithTTL';

const SLICE_NAME = 'webSessionAuth';

const localStorage = new LocalStorageWithTTL('webSession');

const initialState = {
  loading: false,
  errors: {},
  webSession: localStorage.get(false, true),
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    createWebSessionRequest: state => {
      state.loading = true;
      state.errors = {};
    },
    createWebSessionSuccess: (state, action: PayloadAction<{ webSession: string }>) => {
      const { webSession } = action.payload;
      state.loading = false;
      state.errors = {};
      state.webSession = webSession;
    },
    createWebSessionFailure: (state, action: PayloadAction<{ errors: any }>) => {
      const { errors } = action.payload;
      state.loading = false;
      state.errors = errors;
    },
  },
});

const webSessionAuthSelectors = {
  allState: (state: RootState): typeof initialState => state.webSessionAuth,
};

const actions = { ...slice.actions };

export { webSessionAuthSelectors, SLICE_NAME, actions };
export default slice.reducer;
