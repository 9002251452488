// This file should be removed when the api is connected
// ONLY FOR DISPLAY PURPOSES

import { ENHANCE_HARD_CODED_QUIZ_ID, HARD_CODED_QUIZ_ID } from '../Apply/constants';
import { ETagType } from '../components/AssessmentTag/types';
import { ILooseObject, ECategories, ETopics, ECaseStudyClinicalJudgement } from '../types';

const subject = {
  name: 'NCLEX',
  assessmentLevel: ETagType.PROFICIENT,
  id: '7b9a7934-6060-11ed-91bc-3933fa62cf29',
};

// enum ETopics {
//   BASIC_CARE_COMFORT = 'basicCareAndComfort',
//   HEALTH_PROMOTION_MAINTENACE = 'healthPromotionAndMaintenance',
//   MANAGEMENT_OF_CARE = 'managementOfCare',
//   PHYSIOLOGICAL_ADAPTATION = 'physiologicalAdaptation',
//  PSYCHOSOCIAL_INTEGRITY = 'physiolocialAndIntegrity',
//   PHARAMACOLOGICAL_PARENTAL_THERAPIES = 'pharmacologicalAndParentalTherapies',
//   SAFETY_INFECTION_CONTROL = 'safetyInfectionControl',
//   REDUCTION_RISK_POTENTION = 'reductionOfRiskPotential',
// }

type TCategoriesToTopics = {
  [key in ECategories]: ETopics[];
};

type TTopicsToCategories = {
  [key in ETopics]: ECategories[];
};

type TTopicIdMapping = {
  [key in ETopics]: string;
};

type TCategoriesIdMapping = {
  [key in ECategories]: string;
};

type TCaseStudyTopicsClinicalJudgement = {
  [key in ETopics]: ECaseStudyClinicalJudgement[];
};

type TCaseStudyTopicsClinicalJudgementIds = {
  [key: string]: ECaseStudyClinicalJudgement[];
};
const {
  BASIC_CARE_COMFORT,
  HEALTH_PROMOTION_MAINTENACE,
  MANAGEMENT_OF_CARE,
  PHYSIOLOGICAL_ADAPTATION,
  PSYCHOSOCIAL_INTEGRITY,
  PHARAMACOLOGICAL_PARENTAL_THERAPIES,
  SAFETY_INFECTION_CONTROL,
  REDUCTION_RISK_POTENTIAL,
} = ECategories;

const categoriesIdMapping: TCategoriesIdMapping = {
  [BASIC_CARE_COMFORT]: '1',
  [HEALTH_PROMOTION_MAINTENACE]: '2',
  [MANAGEMENT_OF_CARE]: '3',
  [PHYSIOLOGICAL_ADAPTATION]: '4',
  [PSYCHOSOCIAL_INTEGRITY]: '5',
  [PHARAMACOLOGICAL_PARENTAL_THERAPIES]: '6',
  [SAFETY_INFECTION_CONTROL]: '7',
  [REDUCTION_RISK_POTENTIAL]: '8',
};

// const categoriesToTopics: ILooseObject = {
//   3: [
//     '7be89042-6060-11ed-91bc-3933fa62cf29',
//     '7bed20da-6060-11ed-91bc-3933fa62cf29',
//     '7bd39f16-6060-11ed-91bc-3933fa62cf29',
//     '7bb9c802-6060-11ed-91bc-3933fa62cf29',
//     '7bdfca2a-6060-11ed-91bc-3933fa62cf29',
//     '7bdb2524-6060-11ed-91bc-3933fa62cf29',
//     '7bd7954e-6060-11ed-91bc-3933fa62cf29',
//     '7bcfdee4-6060-11ed-91bc-3933fa62cf29',
//     '7bcb7a0c-6060-11ed-91bc-3933fa62cf29',
//     '7bc289c4-6060-11ed-91bc-3933fa62cf29',
//     '7bc6c7a0-6060-11ed-91bc-3933fa62cf29',
//     '7bbde34c-6060-11ed-91bc-3933fa62cf29',
//     '7bb531f2-6060-11ed-91bc-3933fa62cf29',
//     '7bb0acd6-6060-11ed-91bc-3933fa62cf29',
//     '7bacaa82-6060-11ed-91bc-3933fa62cf29',
//     '7ba8304c-6060-11ed-91bc-3933fa62cf29',
//     '7ba38e02-6060-11ed-91bc-3933fa62cf29',
//   ],
//   1: ['7bb531f2-6060-11ed-91bc-3933fa62cf29', '7bb9c802-6060-11ed-91bc-3933fa62cf29'],
//   4: [
//     '7bed20da-6060-11ed-91bc-3933fa62cf29',
//     '7bdb2524-6060-11ed-91bc-3933fa62cf29',
//     '7be89042-6060-11ed-91bc-3933fa62cf29',
//     '7bd39f16-6060-11ed-91bc-3933fa62cf29',
//     '7bcfdee4-6060-11ed-91bc-3933fa62cf29',
//     '7bcb7a0c-6060-11ed-91bc-3933fa62cf29',
//     '7bb9c802-6060-11ed-91bc-3933fa62cf29',
//     '7bc6c7a0-6060-11ed-91bc-3933fa62cf29',
//     '7bc289c4-6060-11ed-91bc-3933fa62cf29',
//     '7bb531f2-6060-11ed-91bc-3933fa62cf29',
//     '7bbde34c-6060-11ed-91bc-3933fa62cf29',
//     '7bacaa82-6060-11ed-91bc-3933fa62cf29',
//     '7ba8304c-6060-11ed-91bc-3933fa62cf29',
//   ],
//   5: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
//   6: [
//     '7bdb2524-6060-11ed-91bc-3933fa62cf29',
//     '7bed20da-6060-11ed-91bc-3933fa62cf29',
//     '7bd7954e-6060-11ed-91bc-3933fa62cf29',
//     '7bcfdee4-6060-11ed-91bc-3933fa62cf29',
//     '7bd39f16-6060-11ed-91bc-3933fa62cf29',
//     '7bc6c7a0-6060-11ed-91bc-3933fa62cf29',
//     '7bb9c802-6060-11ed-91bc-3933fa62cf29',
//     '7bb0acd6-6060-11ed-91bc-3933fa62cf29',
//   ],
//   7: [
//     '7bdb2524-6060-11ed-91bc-3933fa62cf29',
//     '7bd7954e-6060-11ed-91bc-3933fa62cf29',
//     '7bd39f16-6060-11ed-91bc-3933fa62cf29',
//     '7bacaa82-6060-11ed-91bc-3933fa62cf29',
//     '7ba8304c-6060-11ed-91bc-3933fa62cf29',
//     '7bcfdee4-6060-11ed-91bc-3933fa62cf29',
//     '7bbde34c-6060-11ed-91bc-3933fa62cf29',
//     '7bb9c802-6060-11ed-91bc-3933fa62cf29',
//     '7bb531f2-6060-11ed-91bc-3933fa62cf29',
//     '7bb0acd6-6060-11ed-91bc-3933fa62cf29',
//   ],
//   8: [
//     '7bed20da-6060-11ed-91bc-3933fa62cf29',
//     '7bb0acd6-6060-11ed-91bc-3933fa62cf29',
//     '7be89042-6060-11ed-91bc-3933fa62cf29',
//     '7bdb2524-6060-11ed-91bc-3933fa62cf29',
//     '7bd39f16-6060-11ed-91bc-3933fa62cf29',
//     '7bcfdee4-6060-11ed-91bc-3933fa62cf29',
//     '7bc6c7a0-6060-11ed-91bc-3933fa62cf29',
//     '7bb9c802-6060-11ed-91bc-3933fa62cf29',
//     '7ba8304c-6060-11ed-91bc-3933fa62cf29',
//     '7ba38e02-6060-11ed-91bc-3933fa62cf29',
//     '7bb531f2-6060-11ed-91bc-3933fa62cf29',
//   ],
// };

// const topicsToCategories: ILooseObject = {
//   '7bbde34c-6060-11ed-91bc-3933fa62cf29': ['3', '4', '7'],
//   '7ba38e02-6060-11ed-91bc-3933fa62cf29': ['3', '5', '8'],
//   '7be43704-6060-11ed-91bc-3933fa62cf29': [],
//   '7bed20da-6060-11ed-91bc-3933fa62cf29': ['3', '4', '6', '8'],
//   '7ba8304c-6060-11ed-91bc-3933fa62cf29': ['3', '4', '7', '8'],
//   '7bb0acd6-6060-11ed-91bc-3933fa62cf29': ['3', '6', '7', '8'],
//   '7bcb7a0c-6060-11ed-91bc-3933fa62cf29': ['3', '4'],
//   '7bdb2524-6060-11ed-91bc-3933fa62cf29': ['3', '4', '6', '7', '8'],
//   '7bcfdee4-6060-11ed-91bc-3933fa62cf29': ['3', '4', '6', '7', '8'],
//   '7b9f2a56-6060-11ed-91bc-3933fa62cf29': [],
//   '7bc289c4-6060-11ed-91bc-3933fa62cf29': ['3', '4'],
//   '7bb531f2-6060-11ed-91bc-3933fa62cf29': ['1', '3', '4', '7', '8'],
//   '7bd39f16-6060-11ed-91bc-3933fa62cf29': ['3', '4', '6', '7', '8'],
//   '7bc6c7a0-6060-11ed-91bc-3933fa62cf29': ['3', '4', '6', '8'],
//   '7bb9c802-6060-11ed-91bc-3933fa62cf29': ['1', '3', '4', '6', '7', '8'],
//   '7bacaa82-6060-11ed-91bc-3933fa62cf29': ['3', '4', '7'],
//   '7bd7954e-6060-11ed-91bc-3933fa62cf29': ['3', '6', '7'],
//   '7bdfca2a-6060-11ed-91bc-3933fa62cf29': ['3'],
//   '7be89042-6060-11ed-91bc-3933fa62cf29': ['3', '4', '8'],
// };

const topicIdMapping: TTopicIdMapping = {
  [ETopics.ABG]: '7b9f2a56-6060-11ed-91bc-3933fa62cf29',
  [ETopics.CARDIAC]: '7bed20da-6060-11ed-91bc-3933fa62cf29',
  [ETopics.COMPLEX_CARE_CONCEPTS]: 'e0c70e34-12e0-11ee-be56-0242ac120002',
  [ETopics.ENDOCRINE_METABOLIC]: '7bdb2524-6060-11ed-91bc-3933fa62cf29',
  [ETopics.ETHICAL_LEGAL]: '7bd7954e-6060-11ed-91bc-3933fa62cf29',
  [ETopics.EYE_EAR_NOSE_THROAT]: '7bdfca2a-6060-11ed-91bc-3933fa62cf29',
  [ETopics.FUNDAMENTALS]: '7bd39f16-6060-11ed-91bc-3933fa62cf29',
  [ETopics.GASTROINTESTINAL]: '7be89042-6060-11ed-91bc-3933fa62cf29',
  [ETopics.HEMATOLOGIC_ONCOLOGY]: '7bcfdee4-6060-11ed-91bc-3933fa62cf29',
  [ETopics.IMMUNLOGY_INFECTIOUS]: 'e0c71bb8-12e0-11ee-be56-0242ac120002',
  [ETopics.INTEGUMENTARY]: '7bcb7a0c-6060-11ed-91bc-3933fa62cf29',
  [ETopics.LABS]: '7bc6c7a0-6060-11ed-91bc-3933fa62cf29',
  [ETopics.MATERNAL_NEW_BORN]: '7bb9c802-6060-11ed-91bc-3933fa62cf29',
  [ETopics.MEDICATION_CALCULATION]: 'e0c71514-12e0-11ee-be56-0242ac120002',
  [ETopics.MENTAL_HEALTH]: '7ba38e02-6060-11ed-91bc-3933fa62cf29',
  [ETopics.MUSCULOSKELETAL]: '7bc289c4-6060-11ed-91bc-3933fa62cf29',
  [ETopics.NEURO]: '7bbde34c-6060-11ed-91bc-3933fa62cf29',
  [ETopics.PEDIATRIC_HEALTH]: 'e0c70f9c-12e0-11ee-be56-0242ac120002',
  [ETopics.PRIOTIZATION_DELEGATION]: '7bacaa82-6060-11ed-91bc-3933fa62cf29',
  [ETopics.RESPIRATORY]: '7ba8304c-6060-11ed-91bc-3933fa62cf29',
  [ETopics.RENAL_GENITOURINARY]: 'e0c713b6-12e0-11ee-be56-0242ac120002',
  [ETopics.PHARMACOLOGY]: '7bb0acd6-6060-11ed-91bc-3933fa62cf29',
  [ETopics.oncology]: '7be43704-6060-11ed-91bc-3933fa62cf29',
  [ETopics.tests]: '7bb531f2-6060-11ed-91bc-3933fa62cf29',
};

const topicsToCategories: TTopicsToCategories = {
  [ETopics.NEURO]: [
    HEALTH_PROMOTION_MAINTENACE,
    PHARAMACOLOGICAL_PARENTAL_THERAPIES,
    SAFETY_INFECTION_CONTROL,
    MANAGEMENT_OF_CARE,
    PHYSIOLOGICAL_ADAPTATION,
    BASIC_CARE_COMFORT,
    PSYCHOSOCIAL_INTEGRITY,
  ],
  [ETopics.GASTROINTESTINAL]: [
    REDUCTION_RISK_POTENTIAL,
    SAFETY_INFECTION_CONTROL,
    PHYSIOLOGICAL_ADAPTATION,
    PHARAMACOLOGICAL_PARENTAL_THERAPIES,
  ],
  [ETopics.RENAL_GENITOURINARY]: [
    HEALTH_PROMOTION_MAINTENACE,
    PHYSIOLOGICAL_ADAPTATION,
    PSYCHOSOCIAL_INTEGRITY,
    MANAGEMENT_OF_CARE,
    REDUCTION_RISK_POTENTIAL,
    SAFETY_INFECTION_CONTROL,
    PHARAMACOLOGICAL_PARENTAL_THERAPIES,
  ],

  [ETopics.RESPIRATORY]: [
    SAFETY_INFECTION_CONTROL,
    MANAGEMENT_OF_CARE,
    PHYSIOLOGICAL_ADAPTATION,
    HEALTH_PROMOTION_MAINTENACE,
    BASIC_CARE_COMFORT,
    REDUCTION_RISK_POTENTIAL,
  ],
  [ETopics.PEDIATRIC_HEALTH]: [
    MANAGEMENT_OF_CARE,
    REDUCTION_RISK_POTENTIAL,
    PSYCHOSOCIAL_INTEGRITY,
    HEALTH_PROMOTION_MAINTENACE,
    SAFETY_INFECTION_CONTROL,
    PHYSIOLOGICAL_ADAPTATION,
    PHARAMACOLOGICAL_PARENTAL_THERAPIES,
    BASIC_CARE_COMFORT,
  ],
  [ETopics.MATERNAL_NEW_BORN]: [
    PHARAMACOLOGICAL_PARENTAL_THERAPIES,
    REDUCTION_RISK_POTENTIAL,
    SAFETY_INFECTION_CONTROL,
    BASIC_CARE_COMFORT,
    PHYSIOLOGICAL_ADAPTATION,
    MANAGEMENT_OF_CARE,
    HEALTH_PROMOTION_MAINTENACE,
    PSYCHOSOCIAL_INTEGRITY,
  ],

  [ETopics.PRIOTIZATION_DELEGATION]: [MANAGEMENT_OF_CARE, SAFETY_INFECTION_CONTROL, REDUCTION_RISK_POTENTIAL],
  [ETopics.MENTAL_HEALTH]: [
    PHARAMACOLOGICAL_PARENTAL_THERAPIES,
    PHYSIOLOGICAL_ADAPTATION,
    PSYCHOSOCIAL_INTEGRITY,
    BASIC_CARE_COMFORT,
    REDUCTION_RISK_POTENTIAL,
    MANAGEMENT_OF_CARE,
    SAFETY_INFECTION_CONTROL,
    HEALTH_PROMOTION_MAINTENACE,
  ],

  [ETopics.EYE_EAR_NOSE_THROAT]: [
    PHYSIOLOGICAL_ADAPTATION,
    PHARAMACOLOGICAL_PARENTAL_THERAPIES,
    BASIC_CARE_COMFORT,
    MANAGEMENT_OF_CARE,
    HEALTH_PROMOTION_MAINTENACE,
  ],

  [ETopics.PHARMACOLOGY]: [
    MANAGEMENT_OF_CARE,
    PHARAMACOLOGICAL_PARENTAL_THERAPIES,
    PHYSIOLOGICAL_ADAPTATION,
    REDUCTION_RISK_POTENTIAL,
    SAFETY_INFECTION_CONTROL,
    HEALTH_PROMOTION_MAINTENACE,
  ],

  [ETopics.HEMATOLOGIC_ONCOLOGY]: [
    PSYCHOSOCIAL_INTEGRITY,
    PHYSIOLOGICAL_ADAPTATION,
    PHARAMACOLOGICAL_PARENTAL_THERAPIES,
    MANAGEMENT_OF_CARE,
    SAFETY_INFECTION_CONTROL,
    HEALTH_PROMOTION_MAINTENACE,
    BASIC_CARE_COMFORT,
  ],
  [ETopics.MUSCULOSKELETAL]: [
    PHARAMACOLOGICAL_PARENTAL_THERAPIES,
    BASIC_CARE_COMFORT,
    MANAGEMENT_OF_CARE,
    PSYCHOSOCIAL_INTEGRITY,
    HEALTH_PROMOTION_MAINTENACE,
    PHYSIOLOGICAL_ADAPTATION,
    SAFETY_INFECTION_CONTROL,
  ],
  [ETopics.COMPLEX_CARE_CONCEPTS]: [
    MANAGEMENT_OF_CARE,
    PHYSIOLOGICAL_ADAPTATION,
    PSYCHOSOCIAL_INTEGRITY,
    SAFETY_INFECTION_CONTROL,
    REDUCTION_RISK_POTENTIAL,
    HEALTH_PROMOTION_MAINTENACE,
  ],
  [ETopics.FUNDAMENTALS]: [
    MANAGEMENT_OF_CARE,
    PHYSIOLOGICAL_ADAPTATION,
    PHARAMACOLOGICAL_PARENTAL_THERAPIES,
    PSYCHOSOCIAL_INTEGRITY,
    HEALTH_PROMOTION_MAINTENACE,
    SAFETY_INFECTION_CONTROL,
    BASIC_CARE_COMFORT,
    REDUCTION_RISK_POTENTIAL,
  ],

  [ETopics.CARDIAC]: [
    MANAGEMENT_OF_CARE,
    BASIC_CARE_COMFORT,
    SAFETY_INFECTION_CONTROL,
    PHYSIOLOGICAL_ADAPTATION,
    HEALTH_PROMOTION_MAINTENACE,
    REDUCTION_RISK_POTENTIAL,
    PHARAMACOLOGICAL_PARENTAL_THERAPIES,
    PSYCHOSOCIAL_INTEGRITY,
  ],
  [ETopics.LABS]: [PHYSIOLOGICAL_ADAPTATION, MANAGEMENT_OF_CARE],
  [ETopics.MEDICATION_CALCULATION]: [PHARAMACOLOGICAL_PARENTAL_THERAPIES],
  [ETopics.ENDOCRINE_METABOLIC]: [
    MANAGEMENT_OF_CARE,
    HEALTH_PROMOTION_MAINTENACE,
    PHYSIOLOGICAL_ADAPTATION,
    SAFETY_INFECTION_CONTROL,
    PHARAMACOLOGICAL_PARENTAL_THERAPIES,
    REDUCTION_RISK_POTENTIAL,
  ],
  [ETopics.ETHICAL_LEGAL]: [MANAGEMENT_OF_CARE, SAFETY_INFECTION_CONTROL, PSYCHOSOCIAL_INTEGRITY],
  [ETopics.INTEGUMENTARY]: [
    MANAGEMENT_OF_CARE,
    HEALTH_PROMOTION_MAINTENACE,
    SAFETY_INFECTION_CONTROL,
    PHYSIOLOGICAL_ADAPTATION,
    PHARAMACOLOGICAL_PARENTAL_THERAPIES,
  ],
  [ETopics.IMMUNLOGY_INFECTIOUS]: [
    HEALTH_PROMOTION_MAINTENACE,
    PHYSIOLOGICAL_ADAPTATION,
    SAFETY_INFECTION_CONTROL,
    REDUCTION_RISK_POTENTIAL,
    PHARAMACOLOGICAL_PARENTAL_THERAPIES,
    MANAGEMENT_OF_CARE,
  ],

  [ETopics.ABG]: [PHYSIOLOGICAL_ADAPTATION, MANAGEMENT_OF_CARE],
  [ETopics.oncology]: [MANAGEMENT_OF_CARE],
  [ETopics.tests]: [BASIC_CARE_COMFORT],
};

const caseStudyTopicsToClinicalJudgement: Partial<TCaseStudyTopicsClinicalJudgement> = {
  [ETopics.CARDIAC]: [
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.GENERATE_SOLUTIONS,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.RECOGNIZE_CUES,
    ECaseStudyClinicalJudgement.PRIOTIZIE_HYPOTHESES,
  ],
  [ETopics.EYE_EAR_NOSE_THROAT]: [
    ECaseStudyClinicalJudgement.PRIOTIZIE_HYPOTHESES,
    ECaseStudyClinicalJudgement.GENERATE_SOLUTIONS,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.PRIOTIZIE_HYPOTHESES,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
  ],
  [ETopics.ENDOCRINE_METABOLIC]: [
    ECaseStudyClinicalJudgement.RECOGNIZE_CUES,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.EVALUATE_OUTCOMES,
    ECaseStudyClinicalJudgement.PRIOTIZIE_HYPOTHESES,
    ECaseStudyClinicalJudgement.GENERATE_SOLUTIONS,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
  ],
  [ETopics.FUNDAMENTALS]: [
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.PRIOTIZIE_HYPOTHESES,
    ECaseStudyClinicalJudgement.ANALYZE_CUES,
    ECaseStudyClinicalJudgement.EVALUATE_OUTCOMES,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.GENERATE_SOLUTIONS,
  ],
  [ETopics.RENAL_GENITOURINARY]: [
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.GENERATE_SOLUTIONS,
    ECaseStudyClinicalJudgement.EVALUATE_OUTCOMES,
    ECaseStudyClinicalJudgement.PRIOTIZIE_HYPOTHESES,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.EVALUATE_OUTCOMES,
  ],
  [ETopics.IMMUNLOGY_INFECTIOUS]: [
    ECaseStudyClinicalJudgement.RECOGNIZE_CUES,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.GENERATE_SOLUTIONS,
    ECaseStudyClinicalJudgement.PRIOTIZIE_HYPOTHESES,
    ECaseStudyClinicalJudgement.ANALYZE_CUES,
    ECaseStudyClinicalJudgement.EVALUATE_OUTCOMES,
  ],

  [ETopics.INTEGUMENTARY]: [
    ECaseStudyClinicalJudgement.RECOGNIZE_CUES,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.GENERATE_SOLUTIONS,
    ECaseStudyClinicalJudgement.PRIOTIZIE_HYPOTHESES,
    ECaseStudyClinicalJudgement.ANALYZE_CUES,
    ECaseStudyClinicalJudgement.EVALUATE_OUTCOMES,
  ],
  [ETopics.MENTAL_HEALTH]: [
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.GENERATE_SOLUTIONS,
    ECaseStudyClinicalJudgement.ANALYZE_CUES,
    ECaseStudyClinicalJudgement.GENERATE_SOLUTIONS,
    ECaseStudyClinicalJudgement.ANALYZE_CUES,
    ECaseStudyClinicalJudgement.EVALUATE_OUTCOMES,
  ],

  [ETopics.MUSCULOSKELETAL]: [
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.ANALYZE_CUES,
    ECaseStudyClinicalJudgement.GENERATE_SOLUTIONS,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.EVALUATE_OUTCOMES,
  ],
  [ETopics.NEURO]: [
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.ANALYZE_CUES,
    ECaseStudyClinicalJudgement.EVALUATE_OUTCOMES,
    ECaseStudyClinicalJudgement.PRIOTIZIE_HYPOTHESES,
    ECaseStudyClinicalJudgement.RECOGNIZE_CUES,
    ECaseStudyClinicalJudgement.GENERATE_SOLUTIONS,
  ],
  [ETopics.MATERNAL_NEW_BORN]: [
    ECaseStudyClinicalJudgement.PRIOTIZIE_HYPOTHESES,
    ECaseStudyClinicalJudgement.ANALYZE_CUES,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.RECOGNIZE_CUES,
    ECaseStudyClinicalJudgement.GENERATE_SOLUTIONS,
  ],
  [ETopics.HEMATOLOGIC_ONCOLOGY]: [
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.EVALUATE_OUTCOMES,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.EVALUATE_OUTCOMES,
  ],
  [ETopics.RESPIRATORY]: [
    ECaseStudyClinicalJudgement.PRIOTIZIE_HYPOTHESES,
    ECaseStudyClinicalJudgement.GENERATE_SOLUTIONS,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.EVALUATE_OUTCOMES,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.ANALYZE_CUES,
  ],
  [ETopics.PEDIATRIC_HEALTH]: [
    ECaseStudyClinicalJudgement.PRIOTIZIE_HYPOTHESES,
    ECaseStudyClinicalJudgement.GENERATE_SOLUTIONS,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.EVALUATE_OUTCOMES,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.ANALYZE_CUES,
  ],
  [ETopics.GASTROINTESTINAL]: [
    ECaseStudyClinicalJudgement.RECOGNIZE_CUES,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
    ECaseStudyClinicalJudgement.EVALUATE_OUTCOMES,
    ECaseStudyClinicalJudgement.PRIOTIZIE_HYPOTHESES,
    ECaseStudyClinicalJudgement.GENERATE_SOLUTIONS,
    ECaseStudyClinicalJudgement.TAKE_ACTIONS,
  ],
};

const caseStudyTopicIdsToClinicalJudgement: TCaseStudyTopicsClinicalJudgementIds = Object.entries(
  caseStudyTopicsToClinicalJudgement,
).reduce((accum: TCaseStudyTopicsClinicalJudgementIds, [currentTopic, judgement]) => {
  const topicId = topicIdMapping[currentTopic as ETopics];
  accum[topicId] = judgement;

  return accum;
}, {});

const categories = Object.entries(ECategories).map(([key, value]) => ({
  id: categoriesIdMapping[value],
  name: value,
}));

const topicIdsToCategories = Object.entries(topicsToCategories).reduce((accum: ILooseObject, [key, value]) => {
  accum[topicIdMapping[key as ETopics]] = value.map(category => categoriesIdMapping[category]);
  return accum;
}, {});

const categoriesToTopics: TCategoriesToTopics = Object.entries(topicsToCategories).reduce(
  (accum: TCategoriesToTopics, [key, value]) => {
    value.forEach(category => {
      const topic = key as ETopics;
      accum[category].push(topic);
    });
    return accum;
  },
  {
    [BASIC_CARE_COMFORT]: [],
    [HEALTH_PROMOTION_MAINTENACE]: [],
    [MANAGEMENT_OF_CARE]: [],
    [PHYSIOLOGICAL_ADAPTATION]: [],
    [PSYCHOSOCIAL_INTEGRITY]: [],
    [PHARAMACOLOGICAL_PARENTAL_THERAPIES]: [],
    [SAFETY_INFECTION_CONTROL]: [],
    [REDUCTION_RISK_POTENTIAL]: [],
  },
);

const placementQuizIds = [
  '130b48c0-abcf-11ed-99b9-478f295df53e',
  '79feeef4-ca35-11ed-afa1-0242ac120002',
  '76a42ce4-ebb2-11ed-a05b-0242ac120003',
];

const placementTestQuestions = [
  {
    questionId: '1567a600-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '140f41f0-61e6-11ed-b235-81b172206403',
    groupIds: ['7be89042-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '146c07f0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd39f16-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14351970-61e6-11ed-b235-81b172206403',
    groupIds: ['7bdb2524-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14ba9d20-61e6-11ed-b235-81b172206403',
    groupIds: ['7bc289c4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15abb3e0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bacaa82-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '13ed13f0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bed20da-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14f81b50-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16226a80-61e6-11ed-b235-81b172206403',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14c09090-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '168794f0-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba8304c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14482c40-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd7954e-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14b8c860-61e6-11ed-b235-81b172206403',
    groupIds: ['7bbde34c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '9deeea16-c9f5-11ed-afa1-0242ac120002',
    groupIds: ['7bdb2524-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '0ac4d0a4-ca34-11ed-afa1-0242ac120002',
    groupIds: ['7bdb2524-6060-11ed-91bc-3933fa62cf29'],
  },
];

const onBoardingQuizIds = [
  '2bacade0-81c9-11ed-aa13-313f0737df45',
  '167c5976-c37c-11ed-afa1-0242ac120002',
  '37cee2b0-0314-11ee-be56-0242ac120002',
  '4e5508fd-979d-47ad-a56b-e9a604d02f1f',
  'a32b0674-4a76-11ef-9454-0242ac120002',
];

const onBoardingQuestionsV0 = [{ questionId: '2ad0a5c0-81c9-11ed-aa13-313f0737df45' }];

// This the new onboarding quiz version
// Must be
const onBoardingQuestionsV1 = [
  { questionId: 'a730b8e8-c374-11ed-afa1-0242ac120002' },
  { questionId: 'a730c13a-c374-11ed-afa1-0242ac120002' },
  { questionId: 'a730c2c0-c374-11ed-afa1-0242ac120002' },
];
const onBoardingQuestionsV2 = [
  { questionId: 'c316056c-f840-11ed-b67e-0242ac120002' },
  { questionId: '0da76772-0018-11ee-be56-0242ac120002' },
  { questionId: 'c31608aa-f840-11ed-b67e-0242ac120002' },
  { questionId: 'c31609f4-f840-11ed-b67e-0242ac120002' },
  { questionId: 'c3160b34-f840-11ed-b67e-0242ac120002' },
  { questionId: 'c3160c4c-f840-11ed-b67e-0242ac120002' },
  { questionId: 'c3161156-f840-11ed-b67e-0242ac120002' },
  { questionId: 'c31612c8-f840-11ed-b67e-0242ac120002' },
  { questionId: 'c3161462-f840-11ed-b67e-0242ac120002' },
  { questionId: 'c31615b6-f840-11ed-b67e-0242ac120002' },
  { questionId: 'c31616d8-f840-11ed-b67e-0242ac120002' },
  { questionId: 'eca71d8e-0313-11ee-be56-0242ac120002' },
];

const onBoardingQuestionsV3 = [
  { questionId: 'cddcea40-44b8-11ef-9454-0242ac120002' },
  { questionId: 'c982ad4a-44bd-11ef-9454-0242ac120002' },
  { questionId: 'd1ac4878-44bd-11ef-9454-0242ac120002' },
  { questionId: 'e8206f80-44bd-11ef-9454-0242ac120002' },
  { questionId: '494802d2-4f2c-11ef-9454-0242ac120002' },
];

const applyQuizIds = [HARD_CODED_QUIZ_ID];
const applyEnhancementQuizIds = [ENHANCE_HARD_CODED_QUIZ_ID];

const applyQuestionsV0 = [
  { questionId: '04b8214a-5497-11ef-b864-0242ac120002' },
  { questionId: '04b82794-5497-11ef-b864-0242ac120002' },
  { questionId: '04b828ca-5497-11ef-b864-0242ac120002' },
  { questionId: '04b82a1e-5497-11ef-b864-0242ac120002' },
  { questionId: '04b82f1e-5497-11ef-b864-0242ac120002' },
  { questionId: '04b83036-5497-11ef-b864-0242ac120002' },
  { questionId: '04b831da-5497-11ef-b864-0242ac120002' },
  { questionId: '04b832ca-5497-11ef-b864-0242ac120002' },
  { questionId: '04b836c6-5497-11ef-b864-0242ac120002' },
  { questionId: '04b837b6-5497-11ef-b864-0242ac120002' },
  { questionId: '04b838a6-5497-11ef-b864-0242ac120002' },
  { questionId: '030dabb2-5497-11ef-b864-0242ac120002' },
  { questionId: 'd85a2498-78e2-11ef-b864-0242ac120002' },
];

const applyEnhanceQuestionsV0 = [
  { questionId: '52f481f8-7366-11ef-b864-0242ac120002' },
  { questionId: '52f48518-7366-11ef-b864-0242ac120002' },
  { questionId: '52f4866c-7366-11ef-b864-0242ac120002' },
  { questionId: '52f48798-7366-11ef-b864-0242ac120002' },
  { questionId: '52f488c4-7366-11ef-b864-0242ac120002' },
  { questionId: '52f48c3e-7366-11ef-b864-0242ac120002' },
  { questionId: 'b9265e7c-77f0-11ef-b864-0242ac120002' },
];

const headerClicksId = '7913c2b4-7f72-11ef-b864-0242ac120002';

const clicksQuizId = 'c72ba894-7f6e-11ef-b864-0242ac120002';
const clicksQuizV0 = [{ questionId: headerClicksId }];
// const topicsWithCaseStudies = [
//   '7ba8304c-6060-11ed-91bc-3933fa62cf29',
//   '7bed20da-6060-11ed-91bc-3933fa62cf29',
//   '7bdb2524-6060-11ed-91bc-3933fa62cf29',
//   '7bcfdee4-6060-11ed-91bc-3933fa62cf29',
//   '7be89042-6060-11ed-91bc-3933fa62cf29',
//   '7ba38e02-6060-11ed-91bc-3933fa62cf29',
//   '7bc289c4-6060-11ed-91bc-3933fa62cf29',
//   '7bbde34c-6060-11ed-91bc-3933fa62cf29',
//   '7bcb7a0c-6060-11ed-91bc-3933fa62cf29',
//   '7bb9c802-6060-11ed-91bc-3933fa62cf29',
//   '7bd39f16-6060-11ed-91bc-3933fa62cf29',
//   '7bb531f2-6060-11ed-91bc-3933fa62cf29',
//   '7bdfca2a-6060-11ed-91bc-3933fa62cf29',
// ];

// const topicsWithCaseStudies = [
//   '7ba8304c-6060-11ed-91bc-3933fa62cf29',
//   '7bed20da-6060-11ed-91bc-3933fa62cf29',
//   '7bdb2524-6060-11ed-91bc-3933fa62cf29',
//   '7bcfdee4-6060-11ed-91bc-3933fa62cf29',
//   '7be89042-6060-11ed-91bc-3933fa62cf29',
//   '7ba38e02-6060-11ed-91bc-3933fa62cf29',
//   '7bc289c4-6060-11ed-91bc-3933fa62cf29',
//   '7bbde34c-6060-11ed-91bc-3933fa62cf29',
//   '7bcb7a0c-6060-11ed-91bc-3933fa62cf29',
//   '7bb9c802-6060-11ed-91bc-3933fa62cf29',
//   '7bd39f16-6060-11ed-91bc-3933fa62cf29',
//   '7bb531f2-6060-11ed-91bc-3933fa62cf29',
//   '7bdfca2a-6060-11ed-91bc-3933fa62cf29',
// ];

const topicsWithCaseStudies = [
  '7bed20da-6060-11ed-91bc-3933fa62cf29',
  '7bdfca2a-6060-11ed-91bc-3933fa62cf29',
  '7bdb2524-6060-11ed-91bc-3933fa62cf29',
  '7bd39f16-6060-11ed-91bc-3933fa62cf29',
  'e0c713b6-12e0-11ee-be56-0242ac120002',
  'e0c71bb8-12e0-11ee-be56-0242ac120002',
  '7bcfdee4-6060-11ed-91bc-3933fa62cf29',
  '7bcb7a0c-6060-11ed-91bc-3933fa62cf29',
  '7ba38e02-6060-11ed-91bc-3933fa62cf29',
  '7bc289c4-6060-11ed-91bc-3933fa62cf29',
  '7bbde34c-6060-11ed-91bc-3933fa62cf29',
  '7bb9c802-6060-11ed-91bc-3933fa62cf29',
  '7be89042-6060-11ed-91bc-3933fa62cf29',
  'e0c70f9c-12e0-11ee-be56-0242ac120002',
  '7ba8304c-6060-11ed-91bc-3933fa62cf29',
];

const readinessQuestionsV0 = [
  {
    questionId: '167fccc0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bacaa82-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: 'b052dd5e-fe5f-11ed-be56-0242ac120002',
    groupIds: ['7bed20da-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16999650-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '158f7950-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '145858e0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd7954e-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14cb6600-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14e7c7a0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb531f2-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '166e67a0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb531f2-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: 'e51122ea-0589-11ee-be56-0242ac120002',
    groupIds: ['7bd39f16-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14b2ade0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bbde34c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '140b4a50-61e6-11ed-b235-81b172206403',
    groupIds: ['7bed20da-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: 'b29a328e-e8f1-11ed-a05b-0242ac120003',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: 'ab9b99d2-ca06-11ed-afa1-0242ac120002',
    groupIds: ['7bdb2524-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15f789f0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd39f16-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '166c92e0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd39f16-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: 'd027a1d8-ca06-11ed-afa1-0242ac120002',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15ea6a90-61e6-11ed-b235-81b172206403',
    groupIds: ['7bc6c7a0-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '20e6fb00-12f5-11ee-8727-b1ed0bb8baad',
    groupIds: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16272570-61e6-11ed-b235-81b172206403',
    groupIds: ['7be89042-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '141c3a40-61e6-11ed-b235-81b172206403',
    groupIds: ['7be89042-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15485e30-61e6-11ed-b235-81b172206403',
    groupIds: ['7bed20da-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1556dd20-61e6-11ed-b235-81b172206403',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15e4ec50-61e6-11ed-b235-81b172206403',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '164e8390-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '13c40820-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16937bd0-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '164052c0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14222db0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb531f2-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15dab320-61e6-11ed-b235-81b172206403',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '161d1350-61e6-11ed-b235-81b172206403',
    groupIds: ['7bed20da-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1604d060-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba8304c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '141e3610-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb531f2-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '142c66e0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bdb2524-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1684fce0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16104210-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '167126c0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16413d20-61e6-11ed-b235-81b172206403',
    groupIds: ['7bbde34c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15221180-61e6-11ed-b235-81b172206403',
    groupIds: ['7bacaa82-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1416e310-61e6-11ed-b235-81b172206403',
    groupIds: ['7be89042-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16505850-61e6-11ed-b235-81b172206403',
    groupIds: ['7be89042-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1672d470-61e6-11ed-b235-81b172206403',
    groupIds: ['7bc6c7a0-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '13b4ecf0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bc289c4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '163be5f0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14482c40-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd7954e-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15f05e00-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd39f16-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '142d5140-61e6-11ed-b235-81b172206403',
    groupIds: ['7bdb2524-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15b80ff0-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba8304c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '163185b0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bed20da-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '13cb5b20-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14aedd50-61e6-11ed-b235-81b172206403',
    groupIds: ['7bc289c4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15c444f0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bdb2524-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15f4cad0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '20e9ba20-12f5-11ee-8727-b1ed0bb8baad',
    groupIds: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '161460c0-61e6-11ed-b235-81b172206403',
    groupIds: ['7be89042-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15f20bb0-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba8304c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16756c80-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16455bd0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '150f9af0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bacaa82-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16703c60-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1420a710-61e6-11ed-b235-81b172206403',
    groupIds: ['7be89042-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '144741e0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bdb2524-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '161b3e90-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '153a2d60-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba8304c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15eed760-61e6-11ed-b235-81b172206403',
    groupIds: ['7bacaa82-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '155e7e40-61e6-11ed-b235-81b172206403',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15ad6190-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba8304c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '8e7d1920-abca-11ed-b15b-6111e4da0c54',
    groupIds: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14849900-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd39f16-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '167656e0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '13ad24c0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bed20da-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14d74ce0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '153858a0-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba8304c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15db7670-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd39f16-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1557a070-61e6-11ed-b235-81b172206403',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '20df59e0-12f5-11ee-8727-b1ed0bb8baad',
    groupIds: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15c22210-61e6-11ed-b235-81b172206403',
    groupIds: ['7bdb2524-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16470980-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15ccf780-61e6-11ed-b235-81b172206403',
    groupIds: ['7bed20da-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '8eba9750-abca-11ed-b15b-6111e4da0c54',
    groupIds: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '8e56a560-abca-11ed-b15b-6111e4da0c54',
    groupIds: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14574770-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd7954e-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '163ca940-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '20e8cfc0-12f5-11ee-8727-b1ed0bb8baad',
    groupIds: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16461f20-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1561b290-61e6-11ed-b235-81b172206403',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
];

const readinessQuestionsSorted = [
  {
    questionId: '15eed760-61e6-11ed-b235-81b172206403',
    groupIds: ['7bacaa82-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '167656e0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1604d060-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba8304c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '20e9ba20-12f5-11ee-8727-b1ed0bb8baad',
    groupIds: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16999650-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '142c66e0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bdb2524-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15f4cad0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: 'b052dd5e-fe5f-11ed-be56-0242ac120002',
    groupIds: ['7bed20da-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15ad6190-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba8304c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16455bd0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16413d20-61e6-11ed-b235-81b172206403',
    groupIds: ['7bbde34c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15b80ff0-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba8304c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14574770-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd7954e-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16505850-61e6-11ed-b235-81b172206403',
    groupIds: ['7be89042-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1416e310-61e6-11ed-b235-81b172206403',
    groupIds: ['7be89042-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '161d1350-61e6-11ed-b235-81b172206403',
    groupIds: ['7bed20da-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: 'd027a1d8-ca06-11ed-afa1-0242ac120002',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15485e30-61e6-11ed-b235-81b172206403',
    groupIds: ['7bed20da-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15f05e00-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd39f16-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '161460c0-61e6-11ed-b235-81b172206403',
    groupIds: ['7be89042-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '20e6fb00-12f5-11ee-8727-b1ed0bb8baad',
    groupIds: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15db7670-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd39f16-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '140b4a50-61e6-11ed-b235-81b172206403',
    groupIds: ['7bed20da-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '20e8cfc0-12f5-11ee-8727-b1ed0bb8baad',
    groupIds: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '161b3e90-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '167fccc0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bacaa82-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '145858e0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd7954e-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '8e56a560-abca-11ed-b15b-6111e4da0c54',
    groupIds: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15ccf780-61e6-11ed-b235-81b172206403',
    groupIds: ['7bed20da-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: 'e51122ea-0589-11ee-be56-0242ac120002',
    groupIds: ['7bd39f16-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '8eba9750-abca-11ed-b15b-6111e4da0c54',
    groupIds: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '141c3a40-61e6-11ed-b235-81b172206403',
    groupIds: ['7be89042-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15c22210-61e6-11ed-b235-81b172206403',
    groupIds: ['7bdb2524-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '155e7e40-61e6-11ed-b235-81b172206403',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14849900-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd39f16-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1557a070-61e6-11ed-b235-81b172206403',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14d74ce0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '142d5140-61e6-11ed-b235-81b172206403',
    groupIds: ['7bdb2524-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1420a710-61e6-11ed-b235-81b172206403',
    groupIds: ['7be89042-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15dab320-61e6-11ed-b235-81b172206403',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '167126c0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1561b290-61e6-11ed-b235-81b172206403',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '13b4ecf0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bc289c4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15ea6a90-61e6-11ed-b235-81b172206403',
    groupIds: ['7bc6c7a0-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '13cb5b20-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16461f20-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15e4ec50-61e6-11ed-b235-81b172206403',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1672d470-61e6-11ed-b235-81b172206403',
    groupIds: ['7bc6c7a0-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '158f7950-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '8e7d1920-abca-11ed-b15b-6111e4da0c54',
    groupIds: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15f20bb0-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba8304c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '150f9af0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bacaa82-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: 'ab9b99d2-ca06-11ed-afa1-0242ac120002',
    groupIds: ['7bdb2524-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '13ad24c0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bed20da-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '20df59e0-12f5-11ee-8727-b1ed0bb8baad',
    groupIds: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '153a2d60-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba8304c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '164052c0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14e7c7a0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb531f2-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16272570-61e6-11ed-b235-81b172206403',
    groupIds: ['7be89042-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15c444f0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bdb2524-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14222db0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb531f2-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15f789f0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd39f16-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '166c92e0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd39f16-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '163be5f0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '163ca940-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14b2ade0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bbde34c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: 'b29a328e-e8f1-11ed-a05b-0242ac120003',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '153858a0-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba8304c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15221180-61e6-11ed-b235-81b172206403',
    groupIds: ['7bacaa82-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16756c80-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14aedd50-61e6-11ed-b235-81b172206403',
    groupIds: ['7bc289c4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '163185b0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bed20da-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '164e8390-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14cb6600-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16937bd0-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1556dd20-61e6-11ed-b235-81b172206403',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14482c40-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd7954e-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '166e67a0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb531f2-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16104210-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '141e3610-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb531f2-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16703c60-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1684fce0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16470980-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '13c40820-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '144741e0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bdb2524-6060-11ed-91bc-3933fa62cf29'],
  },
];

const readinessQuestionsV1 = [
  {
    questionId: '142d5140-61e6-11ed-b235-81b172206403',
    groupIds: ['7bdb2524-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15e4ec50-61e6-11ed-b235-81b172206403',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: 'b29a328e-e8f1-11ed-a05b-0242ac120003',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '145858e0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd7954e-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '0ac4d0a4-ca34-11ed-afa1-0242ac120002',
    groupIds: ['7bdb2524-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14b2ade0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bbde34c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '142c66e0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bdb2524-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14e7c7a0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb531f2-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15f05e00-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd39f16-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '166c92e0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd39f16-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '155e7e40-61e6-11ed-b235-81b172206403',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1416e310-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd39f16-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1556dd20-61e6-11ed-b235-81b172206403',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: 'b052dd5e-fe5f-11ed-be56-0242ac120002',
    groupIds: ['7bed20da-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16703c60-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16272570-61e6-11ed-b235-81b172206403',
    groupIds: ['7be89042-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '163be5f0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15ad6190-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba8304c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '140b4a50-61e6-11ed-b235-81b172206403',
    groupIds: ['7bed20da-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '13cb5b20-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1604d060-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba8304c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '167656e0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15c22210-61e6-11ed-b235-81b172206403',
    groupIds: ['7bdb2524-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '8e56a560-abca-11ed-b15b-6111e4da0c54',
    groupIds: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '161460c0-61e6-11ed-b235-81b172206403',
    groupIds: ['7be89042-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '167126c0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15db7670-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd39f16-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '8e7d1920-abca-11ed-b15b-6111e4da0c54',
    groupIds: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '150f9af0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bacaa82-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14cb6600-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14574770-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd7954e-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14222db0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '141c3a40-61e6-11ed-b235-81b172206403',
    groupIds: ['7be89042-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '20e6fb00-12f5-11ee-8727-b1ed0bb8baad',
    groupIds: ['7b9a7934-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15485e30-61e6-11ed-b235-81b172206403',
    groupIds: ['7bed20da-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '153858a0-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba8304c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '13c40820-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16756c80-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16413d20-61e6-11ed-b235-81b172206403',
    groupIds: ['7bbde34c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1420a710-61e6-11ed-b235-81b172206403',
    groupIds: ['7be89042-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '13ad24c0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bed20da-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '20e9ba20-12f5-11ee-8727-b1ed0bb8baad',
    groupIds: ['7b9a7934-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16505850-61e6-11ed-b235-81b172206403',
    groupIds: ['7be89042-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15221180-61e6-11ed-b235-81b172206403',
    groupIds: ['7bacaa82-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15ea6a90-61e6-11ed-b235-81b172206403',
    groupIds: ['7bc6c7a0-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15f4cad0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: 'e51122ea-0589-11ee-be56-0242ac120002',
    groupIds: ['7bd39f16-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15c444f0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bdb2524-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15f789f0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd39f16-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '161d1350-61e6-11ed-b235-81b172206403',
    groupIds: ['7bed20da-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16470980-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '20e8cfc0-12f5-11ee-8727-b1ed0bb8baad',
    groupIds: ['7b9a7934-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15f20bb0-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba8304c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '20df59e0-12f5-11ee-8727-b1ed0bb8baad',
    groupIds: ['7b9a7934-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1557a070-61e6-11ed-b235-81b172206403',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '166e67a0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb531f2-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14849900-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd39f16-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '163ca940-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1561b290-61e6-11ed-b235-81b172206403',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '167fccc0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bacaa82-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16461f20-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1672d470-61e6-11ed-b235-81b172206403',
    groupIds: ['7bc6c7a0-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16104210-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16455bd0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: 'd027a1d8-ca06-11ed-afa1-0242ac120002',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15ccf780-61e6-11ed-b235-81b172206403',
    groupIds: ['7bed20da-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14d74ce0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16999650-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '16937bd0-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba38e02-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '1684fce0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '164052c0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '8eba9750-abca-11ed-b15b-6111e4da0c54',
    groupIds: ['7bd39f16-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '144741e0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bdb2524-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14482c40-61e6-11ed-b235-81b172206403',
    groupIds: ['7bd7954e-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '158f7950-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '164e8390-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '141e3610-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb9c802-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '14aedd50-61e6-11ed-b235-81b172206403',
    groupIds: ['7bc289c4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '153a2d60-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba8304c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15eed760-61e6-11ed-b235-81b172206403',
    groupIds: ['7bacaa82-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '13b4ecf0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bc289c4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15b80ff0-61e6-11ed-b235-81b172206403',
    groupIds: ['7ba8304c-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '15dab320-61e6-11ed-b235-81b172206403',
    groupIds: ['7bcfdee4-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '163185b0-61e6-11ed-b235-81b172206403',
    groupIds: ['7bed20da-6060-11ed-91bc-3933fa62cf29'],
  },
  {
    questionId: '161b3e90-61e6-11ed-b235-81b172206403',
    groupIds: ['7bb0acd6-6060-11ed-91bc-3933fa62cf29'],
  },
];

export {
  subject,
  ETopics,
  categories,
  categoriesToTopics,
  topicsToCategories,
  topicIdsToCategories,
  placementTestQuestions,
  onBoardingQuestionsV0,
  onBoardingQuestionsV1,
  onBoardingQuestionsV2,
  topicsWithCaseStudies,
  readinessQuestionsV0,
  readinessQuestionsSorted,
  caseStudyTopicIdsToClinicalJudgement,
  placementQuizIds,
  onBoardingQuizIds,
  readinessQuestionsV1,
  onBoardingQuestionsV3,
  applyQuestionsV0,
  applyQuizIds,
  applyEnhanceQuestionsV0,
  applyEnhancementQuizIds,
  clicksQuizV0,
  headerClicksId,
  clicksQuizId,
};
