import { Radio, TableContainer } from '@mui/material';
import { Table as StyledTable } from '../../SmartQuiz/question/CaseStudy/MatrixChoice/styled';
import { IMatrixComponentUI } from './types';
import { TableHeader, TableHeaderContent } from './styled';

const Table = ({ header, rows, response, onChange }: IMatrixComponentUI) => {
  const isResponse = !!response;
  const containsExtra = header.some(option => option.extra);
  return (
    <TableContainer>
      <StyledTable>
        <tr>
          <th />
          {header.map(option => (
            <TableHeader isFlex={containsExtra && !option.extra}>
              <TableHeaderContent>
                <span>{option.value}</span>

                {option.extra}
              </TableHeaderContent>
            </TableHeader>
          ))}
        </tr>
        {rows.map((row, index) => (
          <tr key={index}>
            <td>{row.value}</td>
            {header.map(option => {
              return (
                <td>
                  <Radio
                    key={index}
                    checked={response?.[row.id] === option.id}
                    value={option.id}
                    onChange={() => onChange(row.id, option.id)}
                    //disabled={isDone}
                  />
                </td>
              );
            })}
          </tr>
        ))}
      </StyledTable>
    </TableContainer>
  );
};

export default Table;
