import { SecondaryTitleText } from '../../sharedStyled';
import { IContent } from '../../types';
import {
  QuestionExplanationContainer,
  CorrectAnswer,
  ChoiceExplanationContainer,
  ChoiceHeader,
  ChoiceExplanation,
} from './styled';
import TemplateRenderer from './templates';
import { en } from '../../../i18n';

const { explanation, rationale, correctAnswerTitle } = en.smartQuiz;

interface IProps {
  content?: IContent;
  answer: string;
  isCorrect?: boolean;
  isShortAnswer?: boolean;
}

const Explanation = ({ content, answer, isCorrect, isShortAnswer }: IProps) => {
  if (isShortAnswer && !content) return null;
  if (isShortAnswer)
    return (
      <QuestionExplanationContainer isCorrect={isCorrect}>
        <SecondaryTitleText>
          <div>{explanation}:</div>
        </SecondaryTitleText>

        <CorrectAnswer>{content && <TemplateRenderer fullWidthMobile content={content} />}</CorrectAnswer>
      </QuestionExplanationContainer>
    );

  return (
    <ChoiceExplanationContainer isCorrect>
      <ChoiceHeader>
        <span> {correctAnswerTitle}</span>
        <span>{answer} </span>
      </ChoiceHeader>

      <ChoiceExplanation>
        <span>{rationale}</span>
        {content && <TemplateRenderer fullWidthMobile width="80%" content={content} />}
      </ChoiceExplanation>
    </ChoiceExplanationContainer>
  );
};

export default Explanation;
