import Matrix from '../../components/Matrix';
import { IStepComponent } from '../types';
import { InputContainer, MatrixInputContainer, QuestionSubHeader, Title } from './styled';

import { en } from '../../../i18n';
import useStep from './useStep';
import { ILooseObject } from '../../types';

const PatientCareRating = ({ question }: IStepComponent) => {
  const { renderSanitizedText, onChangeAnswer, currentResponse } = useStep({ question });

  const header = [
    { id: 1, value: '1' },
    { id: 2, value: '2' },
    { id: 3, value: '3' },
    { id: 4, value: '4' },
  ];

  const rows =
    question.content.choices?.map(choice => ({ id: choice.id, value: choice.content.choice?.content.text || '' })) ||
    [];

  return (
    <>
      <Title>{renderSanitizedText(question.content.question.content.text)}</Title>
      <QuestionSubHeader>{en.apply.patientCare.subHeader}</QuestionSubHeader>
      <MatrixInputContainer>
        <Matrix
          rowUniquenessConstraint
          header={header}
          rows={rows}
          onChange={onChangeAnswer}
          response={currentResponse as ILooseObject}
        />
      </MatrixInputContainer>
    </>
  );
};

export default PatientCareRating;
