import { styled } from '@mui/material/styles';
import { shouldForwardProps } from '../../utils';

interface ITableHeader {
  isFlex?: boolean;
}

const TableHeaderContent = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TableHeader = styled('th', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isFlex']),
})<ITableHeader>(
  ({ isFlex }) => `
${isFlex ? 'vertical-align:top;' : ''}
  
`,
);
export { TableHeaderContent, TableHeader };
