import { styled } from '@mui/material/styles';
import { QuestionContainer } from '../styled';
import { colors } from '../../../themes';

const ReadyQuestionContainer = styled(QuestionContainer)`
  width: 100%;
  justify-content: center;

  padding-left: 16px;
  padding-right: 16px;

  ${props => props.theme.breakpoints.down('md')} {
    padding-left: 12px;
    padding-right: 12px;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    padding-top: ${props => (props.error ? '64px' : '0px')};
  }

  box-sizing: border-box;
  align-items: center;
  ${props => props.theme.breakpoints.down('md')} {
    width: 100%;
  }
  span {
    color: #181818;
    text-fill-color: #181818;
  }
  input {
    width: 45%;
    margin: auto;
    ${props => props.theme.breakpoints.down('md')} {
      width: 75%;
    }
    ${props => props.theme.breakpoints.down('sm')} {
      width: 90%;
    }
  }

  .purple {
    font-weight: 800;
    color: ${colors.primary};
    text-fill-color: ${colors.primary};
  }
  .italic {
    font-style: italic;
  }
  .smartn {
    font-weight: 800;
    color: ${colors.primary};
    text-fill-color: ${colors.primary};
  }

  .apostropohe {
    color: ${colors.smartNApostrophe};
    text-fill-color: ${colors.smartNApostrophe};
  }
`;

const SubTitleA = styled('h2')`
  text-align: center;
`;

const SubTitleB = styled('h3')`
  font-weight: normal;
  text-align: center;
`;

const SubTitleC = styled('h3')`
  font-weight: normal;
  text-align: center;
`;

const ListContainer = styled('div')`
  display: flex;
  ${props => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    gap: 64px;
  }
  gap: 32px;
  margin-bottom: 64px;
  margin-top: 64px;
`;

const ListItem = styled('div')`
  display: flex;
  flex-direction: column;
  width: 33.33333333333%;
  ${props => props.theme.breakpoints.down('md')} {
    width: auto;
  }
`;
const ListTitle = styled('div')`
  font-weight: 700;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  margin-bottom: 16px;
  color: ${colors.lightblack};
  align-items: center;

  svg {
    width: 64px;
    height: 50px;
    cursor: pointer;
  }
  ${props => props.theme.breakpoints.down('md')} {
    text-align: center;
    margin-left: 0;
  }
`;

const ListSubTitle = styled('div')`
  text-align: center;
  margin-bottom: 24px;
  font-weight: 700;

  color: ${colors.lightblack};
  ${props => props.theme.breakpoints.down('md')} {
    margin-bottom: 12px;
    margin-left: 0;
  }
`;

const ListDetailContainer = styled('div')`
  display: flex;
  justify-content: center; /* Centers the content horizontally */
`;

const ListDetail = styled('div')`
  text-align: left;
  position: relative;
  left: 50%;
  transform: translateX(-45%);
  font-size: min(16px, 1rem);
  color: ${colors.lightblack};
  span {
    display: inline-block;
  }
  ${props => props.theme.breakpoints.down('md')} {
    text-align: center;
    left: 0;
    transform: none;
  }
`;

const PreButton = styled('div')`
  text-align: center;
  font-weight: 700;
  font-size: 1.25rem;
`;

const ContentContainer = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 5%;
  padding-right: 5%;
  position: relative;
`;

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  span,
  button {
    font-weight: 700;
    color: ${colors.white} !important;
    -webkit-text-fill-color: ${colors.white} !important;
  }
`;

const DisclaimerContainer = styled('div')`
  position: absolute;
  font-size: 0.875rem;
  bottom: 0;
  right: 35px;
  ${props => props.theme.breakpoints.down('md')} {
    position: static;
    margin-top: 8px;
  }
`;

export {
  ReadyQuestionContainer,
  SubTitleA,
  SubTitleB,
  SubTitleC,
  ListItem,
  ListTitle,
  ListDetail,
  PreButton,
  ListContainer,
  ContentContainer,
  ButtonContainer,
  ListSubTitle,
  DisclaimerContainer,
  ListDetailContainer,
};
