import { Saga } from 'redux-saga';
import { all } from 'redux-saga/effects';
import quiz from '../StudySpace/redux/sagas';
import topics from '../StudySpace/redux/topics/sagas';
import auth from '../Auth/redux/sagas';
import clicks from '../ClicksQuiz/redux/sagas';
import onboarding from '../OnBoarding/redux/sagas';
import events from '../events/redux/sagas';
import subscriptions from '../Membership/redux/sagas';
import nurseGPT from '../NurseGPT/redux/sagas';
import apply from '../Apply/redux/sagas';
import webSessionAuth from '../webSessionAuth/redux/sagas';

function* rootSaga() {
  yield all(
    [
      ...topics,
      ...quiz,
      //...resources,
      //...uploads,
      ...auth,
      ...events,
      ...onboarding,
      ...subscriptions,
      ...nurseGPT,
      ...apply,
      ...clicks,
      ...webSessionAuth,
    ].map((saga: Saga) => saga()),
  );
}

export default rootSaga;
