import { sampleSize, sample } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Button from '../components/Button';
import {
  PageContainer,
  ChatContainer,
  SessionsContainer,
  SessionsHeader,
  PlusButton,
  NoSessions,
  AboutButton,
  ModalOverlay,
  ModalContainer,
  ModalTitle,
  SessionsList,
  SessionsSection,
  SessionsSectionTitle,
  MobileHeader,
  ModalBody,
  DeleteModalButtonsContainer,
  InfiniteLoader,
  ButtonTipsPopupContainer,
} from './styled';
import { en } from '../../i18n';
import { useNurseGPT, useSeoTags } from '../hooks';
import { ISession, EUserRole } from '../types';
import { ReactComponent as PlusIcon } from '../../images/plusWhite.svg';
import { ReactComponent as InfoIcon } from '../../images/questionMark.svg';
import { ReactComponent as FolderIcon } from '../../images/folder.svg';
import Spinner from '../components/Spinner';
import Session from './components/Session';
import Tips from './components/tips';
import ChatBody from './components/ChatBody';
import PromptInput from './components/PromptInput';
import ErrorModal from './components/ErrorModal';
import { useMediaQuery, useTheme } from '@mui/material';

const {
  title,
  noSessionsTitle,
  noSessionsBody,
  about,
  prompts,
  deleteSessionTitle,
  willDelete,
  cantUndone,
  deleteButton,
  cancel,
  closeTips,
} = en.nurseGPT;

const nursePrompts = sampleSize(Object.values(prompts.nurse), 4).map(category => sample(category));
const studentPrompts = sampleSize(Object.values(prompts.student), 4).map(category => sample(category));

const NurseGPT = () => {
  const seo = useSeoTags({ pageName: 'askSmartn' });

  const {
    state: {
      chat,
      prompt,
      messagesWrapperRef,
      typedResponseRef,
      userFirstLetter,
      loadingResponse,
      isUnstarted,
      noSessions,
      tipsPopup,
      groupedSessions,
      mobileDrawerOpen,
      loadingSessions,
      inputRef,
      typedResponse,
      clickedSessionMenu,
      showDeleteModal,
      sessionToDelete,
      sessionToRename,
      sessionsTitlesRef,
      sessionsListRef,
      currentSession,
      svgLoader,
      showErrorModal,
      storedRole,
      isRoleLoading,
      currentFile,
      fileInputKey,
      copyToolTipText,
      fileUpload,
      errors,
      firstButtonClick,
      isMedium,
      isReconnecting,
    },
    control: {
      handlePromptChange,
      onKeyDown,
      handlePromptSubmit,
      handleStartNewChat,
      toggleTipsPopup,
      setCurrentSession,
      toggleMobileDrawer,
      handleExampleClick,
      getPlaceholder,
      handleSessionMenuClick,
      handleSessionMenuClickaway,
      handleSessionDelete,
      toggleDeleteModal,
      handleDeleteClick,
      handleRenameClick,
      handleRenameBlur,
      onRenameKeyDown,
      displaySessionMenuUp,
      refresh,
      handleMessagesScroll,
      onFileChange,
      cancelFileUpload,
      setCopyTooltipText,
      setFirstButtonClick,
      onClickAbout,
    },
  } = useNurseGPT();

  const renderSessionsList = () => {
    if (noSessions && loadingSessions) return <Spinner loading={loadingSessions} />;
    if (noSessions)
      return (
        <NoSessions>
          <div>{noSessionsTitle}</div>
          <div>{noSessionsBody}</div>
        </NoSessions>
      );
    return (
      <SessionsList ref={sessionsListRef}>
        {Object.entries(groupedSessions).map(([section, sessions]) => (
          <SessionsSection>
            <SessionsSectionTitle>{section}</SessionsSectionTitle>
            {sessions.map((session: ISession) => (
              <Session
                key={session.sessionId}
                session={session}
                clickedSessionMenu={clickedSessionMenu}
                sessionToRename={sessionToRename}
                sessionsTitlesRef={sessionsTitlesRef}
                setCurrentSession={setCurrentSession}
                handleSessionMenuClick={handleSessionMenuClick}
                handleSessionMenuClickaway={handleSessionMenuClickaway}
                handleDeleteClick={handleDeleteClick}
                handleRenameClick={handleRenameClick}
                handleRenameBlur={handleRenameBlur}
                onRenameKeyDown={onRenameKeyDown}
                displaySessionMenuUp={displaySessionMenuUp}
              />
            ))}
          </SessionsSection>
        ))}
        {loadingSessions && (
          <InfiniteLoader>
            <Spinner loading={loadingSessions} inline size="20px" />
          </InfiniteLoader>
        )}
      </SessionsList>
    );
  };

  const renderSessions = () => {
    const hideTipsPops = isMedium && !mobileDrawerOpen;
    const { isHidden, Component } = renderTipsPopup();

    const renderAboutButton = () => {
      const component = (
        <AboutButton onClick={onClickAbout}>
          {isHidden && <InfoIcon />}
          {isHidden ? about : closeTips}
        </AboutButton>
      );
      if (!isMedium) return component;
      if (isMedium && isHidden) return component;
      if (!isHidden) return null;
      return component;
    };

    return (
      <SessionsContainer mobileDrawerOpen={mobileDrawerOpen}>
        <SessionsHeader>
          <div>
            <h1>{title}</h1>
          </div>
          <PlusButton onClick={handleStartNewChat}>
            <PlusIcon />
          </PlusButton>
        </SessionsHeader>
        {renderSessionsList()}

        {!hideTipsPops && (
          <ButtonTipsPopupContainer isHidden={isHidden}>
            {Component}
            {/* <Tips isHidden={!tipsPopup} /> */}
            {/* <AboutButton onClick={onClickAbout}>
              {isHidden && <InfoIcon />}
              {isHidden ? about : closeTips}
            </AboutButton> */}
            {renderAboutButton()}
          </ButtonTipsPopupContainer>
        )}
      </SessionsContainer>
    );
  };

  const renderTipsPopup = () => {
    const isHidden = () => {
      if (!noSessions) return !tipsPopup;
      if (!firstButtonClick) return false;
      return !tipsPopup;
    };

    if ((!firstButtonClick && !noSessions) || loadingSessions) return { isHidden: true, Component: null };
    const isHiddenComp = isHidden();

    return {
      isHidden: isHiddenComp,
      Component: (
        <Tips
          close={() => {
            setFirstButtonClick(true);
            toggleTipsPopup();
          }}
          isHidden={isHidden()}
        />
      ),
    };
  };

  const renderDeleteModal = () => {
    return (
      showDeleteModal && (
        <ModalOverlay zIndex={11}>
          <ClickAwayListener onClickAway={toggleDeleteModal}>
            <ModalContainer>
              <ModalTitle>{deleteSessionTitle}</ModalTitle>
              <ModalBody>
                {willDelete}
                <span>"{sessionToDelete?.title}"</span>
                {cantUndone}
              </ModalBody>
              <DeleteModalButtonsContainer>
                <Button variant="secondary" onClick={toggleDeleteModal}>
                  {cancel}
                </Button>
                <Button variant="primary" onClick={handleSessionDelete}>
                  {deleteButton}
                </Button>
              </DeleteModalButtonsContainer>
            </ModalContainer>
          </ClickAwayListener>
        </ModalOverlay>
      )
    );
  };

  const renderMobileHeader = () => {
    return (
      <MobileHeader>
        {mobileDrawerOpen ? (
          <CloseIcon
            onClick={() => {
              toggleMobileDrawer();
              setFirstButtonClick(false);
            }}
          />
        ) : (
          <FolderIcon onClick={toggleMobileDrawer} />
        )}
        <div>
          <h1>{title}</h1>
        </div>
        <PlusButton onClick={handleStartNewChat}>
          <PlusIcon />
        </PlusButton>
      </MobileHeader>
    );
  };

  const renderDimConnection = () => {
    if (isReconnecting && !showErrorModal) return <ModalOverlay zIndex={11} />;
    return null;
  };

  return (
    <PageContainer>
      {seo}
      {/* {renderAboutModal()} */}
      {renderDeleteModal()}
      {renderSessions()}
      {renderMobileHeader()}
      {renderDimConnection()}
      {showErrorModal && <ErrorModal refresh={refresh} />}
      <ChatContainer>
        <ChatBody
          isUnstarted={isUnstarted}
          messagesWrapperRef={messagesWrapperRef}
          handleMessagesScroll={handleMessagesScroll}
          currentSession={currentSession}
          chat={chat}
          loadingResponse={loadingResponse}
          typedResponse={typedResponse}
          typedResponseRef={typedResponseRef}
          svgLoader={svgLoader}
          copyToolTipText={copyToolTipText}
          setCopyTooltipText={setCopyTooltipText}
          isUnstartedLoading={isRoleLoading}
          prompts={storedRole === EUserRole.UNDERGRAD ? studentPrompts : nursePrompts}
          handleExampleClick={handleExampleClick}
          userIconLetter={userFirstLetter}
        />
        <PromptInput
          prompt={prompt}
          handlePromptChange={handlePromptChange}
          onKeyDown={onKeyDown}
          inputRef={inputRef}
          getPlaceholder={getPlaceholder}
          handlePromptSubmit={handlePromptSubmit}
          currentFile={currentFile}
          loadingResponse={loadingResponse}
          fileUpload={fileUpload}
          fileInputKey={fileInputKey}
          onFileChange={onFileChange}
          cancelFileUpload={cancelFileUpload}
          errors={errors}
          isReconnecting={isReconnecting}
        />
      </ChatContainer>
    </PageContainer>
  );
};

export default NurseGPT;
