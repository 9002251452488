import { HeaderTrial } from './styled';
import { en } from '../../i18n';
import { Link } from 'react-router-dom';
import { applyPath } from '../Router/paths';

const { header } = en;

interface IEnhanceApplyBanner {
  isStarted?: boolean;
}

const EnhanceApplyBanner = ({ isStarted }: IEnhanceApplyBanner) => {
  return (
    <HeaderTrial>
      {header.enhanceBannerBeforeButtonText}
      &nbsp;
      <Link to={applyPath}>{header.enhanceBannerButtonText}</Link>
      &nbsp;
      {isStarted ? header.enhanceBannerIncompleteAfterButtonText : header.enhanceBannnerAfterButtonText}
    </HeaderTrial>
  );
};

export default EnhanceApplyBanner;
