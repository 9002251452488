import { HeaderTrial } from './styled';
import { en } from '../../i18n';
import { Link } from 'react-router-dom';
import { applyPath } from '../Router/paths';
import useAttempts from '../hooks/useAttempts';
import { useSelector } from 'react-redux';
import { actions, clicksSelector } from '../ClicksQuiz/redux/slice';
import { useDispatch } from '../hooks';
import { clicksQuizId, headerClicksId } from '../StudySpace/staticConstants';
import { EQuestionIDS } from '../ClicksQuiz/types';

interface IApplyRoleBanner {
  text: string;
  button: string;
  textSuffix?: string;
  id: EQuestionIDS;
}

const ApplyRoleBanner = ({ text, button, textSuffix, id }: IApplyRoleBanner) => {
  const { currentClicksAttempt } = useAttempts();
  const { dispatch } = useDispatch();
  const { questionAnswers } = useSelector(clicksSelector.allState);

  const recordClick = () => {
    if (!currentClicksAttempt) {
      dispatch(
        actions.attemptQuiz({
          quizId: clicksQuizId,
          response: {
            questionId: headerClicksId,
            response: id,
          },
        }),
      );

      return;
    }

    dispatch(
      actions.submitAnswer({
        attemptId: currentClicksAttempt.attemptId,
        storedResponse: questionAnswers[headerClicksId],
        response: {
          questionId: headerClicksId,
          response: id,
        },
      }),
    );
  };

  return (
    <HeaderTrial>
      {text}
      &nbsp;
      <Link onClick={recordClick} to={applyPath}>
        {button}
      </Link>
      {textSuffix && <>&nbsp;{textSuffix}</>}
    </HeaderTrial>
  );
};

export default ApplyRoleBanner;
