import useAttempts from '../hooks/useAttempts';
import { useDispatch } from '../hooks';
import { ENHANCE_HARD_CODED_QUIZ_ID } from './constants';
import { actions, applySelector } from './redux/slice';
import Button from '../components/Button';
import { en } from '../../i18n';
import { useSelector } from 'react-redux';
import { EApplyQuizState } from '../types';
import { EnhanceDoneText } from './styled';

const { apply } = en;
const { exit } = apply;

const useStartApplyEnhance = () => {
  const { currentApplyEnhancementAttempt, applyQuizState } = useAttempts();
  const { loadingStartQuiz, questionAnswers } = useSelector(applySelector.allState);
  const { dispatch } = useDispatch();

  const enhanceProfile = () => {
    const getLastAnswerIndex = () => {
      if (!currentApplyEnhancementAttempt || !questionAnswers) return 0;
      let lastSeenIndex = -1;
      currentApplyEnhancementAttempt.questions.forEach((question, index) => {
        if (questionAnswers[question.questionId]?.isDone) {
          lastSeenIndex = index;
        }
      });

      if (lastSeenIndex === currentApplyEnhancementAttempt.questions.length - 1 || lastSeenIndex < 0) return 0;

      return lastSeenIndex + 1;
    };
    if (!currentApplyEnhancementAttempt)
      dispatch(actions.attemptQuiz({ quizId: ENHANCE_HARD_CODED_QUIZ_ID, isEnhance: true }));
    else {
      const lastIndex = getLastAnswerIndex();
      dispatch(actions.applyEnhancementsStarted({ currentIndex: lastIndex }));
    }
  };

  const renderEnhanceButton = () => {
    if (applyQuizState === EApplyQuizState.BASE_DONE || !currentApplyEnhancementAttempt) {
      return (
        <Button size="small" loading={loadingStartQuiz} onClick={enhanceProfile} variant="secondary">
          {exit.enhanceProfileStartButton}
        </Button>
      );
    }

    if (applyQuizState === EApplyQuizState.ENHANCE_STARTED && currentApplyEnhancementAttempt) {
      return (
        <Button size="small" onClick={enhanceProfile} variant="secondary">
          {exit.enhanceProfileContinue}
        </Button>
      );
    }

    return <EnhanceDoneText>{exit.enhanceProfileCompleted}</EnhanceDoneText>;
  };

  const enhanceButton = null;
  return {
    enhanceProfile,
    enhanceButton,
    applyQuizState,
    renderEnhanceButton,
  };
};

export default useStartApplyEnhance;
