import { actions } from './slice';
import { call, put, takeLatest } from 'redux-saga/effects';
import { endpoints } from '../../constants';
import axiosRequest, { ERequestMethods } from '../../axios';
import LocalStorageWithTTL from '../../classes/LocalStorageWithTTL';

function* createWebSession() {
  try {
    const { data: webSession } = yield call(axiosRequest, {
      url: `${endpoints.webSessionAuth}`,
      method: ERequestMethods.POST,
      useAuthorization: false,
    });

    const localStorage = new LocalStorageWithTTL('webSession');
    localStorage.set(webSession);
    yield put(actions.createWebSessionSuccess({ webSession }));
  } catch (errors) {
    yield put(actions.createWebSessionFailure({ errors }));
  }
}

function* watchCreateWebSession() {
  yield takeLatest(actions.createWebSessionRequest, createWebSession);
}

const sagas = [watchCreateWebSession];

export default sagas;
