import { IStepComponent } from '../types';
import { SkillChoiceRow, SkillsChoicesContainer } from './styled';
import useStep from './useStep';

const Skills = ({ question }: IStepComponent) => {
  const { title, renderMultipleChoices, onChangeAnswer } = useStep({ question });
  const choices = renderMultipleChoices('checkbox', 5);

  const combineToPairs = (arr: any[] | null): any[][] | null => {
    if (!arr) return null;

    // Use reduce to group the array into pairs
    return arr.reduce((acc: any[][], current, index) => {
      if (index % 2 === 0) {
        // Push a new pair array for even index
        acc.push([current]);
      } else {
        // Add the current element to the last array for odd index
        acc[acc.length - 1].push(current);
      }
      return acc;
    }, []);
  };

  const choicePairs = combineToPairs(choices);

  const renderRowChoices = () => {
    return choicePairs?.map((pair, index) => (
      <SkillChoiceRow key={index}>
        <>{pair}</>
      </SkillChoiceRow>
    ));
  };
  return (
    <>
      {title}
      <SkillsChoicesContainer>{renderRowChoices()}</SkillsChoicesContainer>
    </>
  );
};

export default Skills;
