import Matrix from '../../components/Matrix';
import { IStepComponent } from '../types';
import { InputContainer, MatrixInputContainer, QuestionSubHeader, Title } from './styled';

import { en } from '../../../i18n';
import useStep from './useStep';
import { ILooseObject } from '../../types';

const JobImportance = ({ question }: IStepComponent) => {
  const { renderSanitizedText, onChangeAnswer, currentResponse } = useStep({ question });

  const header = [
    { id: 3, value: 'Very Important' },
    { id: 2, value: 'Important' },
    { id: 1, value: 'Slightly Important' },
    { id: 0, value: 'Not Important' },
  ];

  const rows =
    question.content.choices?.map(choice => ({ id: choice.id, value: choice.content.choice?.content.text || '' })) ||
    [];

  return (
    <>
      <Title>{renderSanitizedText(question.content.question.content.text)}</Title>
      <MatrixInputContainer>
        <Matrix header={header} rows={rows} onChange={onChangeAnswer} response={currentResponse as ILooseObject} />
      </MatrixInputContainer>
    </>
  );
};

export default JobImportance;
