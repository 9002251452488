import { useHistory } from 'react-router';
import {
  dashboardPath,
  nestedReviewQuizResultsPath,
  membershipPath,
  readinessAssesmentPath,
  reviewResultsAnswersPath,
  placementTestPath,
  nurseGPTPath,
  applyPath,
  registerPath,
  loginPath,
} from '../Router/paths';
import { subject } from '../StudySpace/staticConstants';
import { IAttempt } from '../types';

const useNavigations = () => {
  const history = useHistory();

  const navigateToResults = (attempt: IAttempt) => {
    const search = `${new URLSearchParams({ attemptId: attempt.attemptId })}`;

    history.push({
      pathname: `/${subject.id}/${nestedReviewQuizResultsPath}`,
      search,
    });
  };

  const navigateToResultsAnswers = (attempt: IAttempt, topics: string[] = []) => {
    const topicIds = topics.join(',');
    const search = `${new URLSearchParams({ attemptId: attempt.attemptId, topicIds })}`;
    history.push({
      pathname: `/${subject.id}/${nestedReviewQuizResultsPath}/${reviewResultsAnswersPath}`,
      search,
    });
  };

  const navigateToStudySpace = (
    isViewingPastQuizzes: boolean = false,
    isManualCreate = false,
    onlyIncomplete?: boolean,
  ) => {
    history.push({
      pathname: `${subject.id}/study-space`,
      state: { isViewingPastQuizzes, isManualCreate, onlyIncomplete },
    });
  };

  const navigateToDashboard = () => {
    history.push(dashboardPath);
  };

  const navigateToContinueQuiz = (attempt: IAttempt) => {
    const search = `${new URLSearchParams({ attemptId: attempt.attemptId })}`;
    if (attempt.isReadiness) {
      history.push({
        pathname: readinessAssesmentPath,
        search,
      });
      return;
    }
    history.push({
      pathname: `/${subject.id}/study-space`,
      search,
    });
  };

  const navigateToMembership = () => {
    history.push({
      pathname: membershipPath,
      //state: { plan: plans[2] },
    });
  };

  const navigateToReadiness = (newStart?: boolean) => {
    history.push({
      pathname: readinessAssesmentPath,
      state: { newStart: !!newStart },
    });
  };

  const navigateToMembershipUnconditional = () => {
    history.push(membershipPath);
  };

  const navigateToBaseLineTest = (enforcePlacement?: boolean) => {
    history.push({
      pathname: placementTestPath,
      state: {
        enforcePlacement,
      },
    });
  };
  const navigateToNurseGPT = () => {
    history.push(nurseGPTPath);
  };

  const navigateToHome = (sectionEnum?: number) => {
    history.push({ pathname: dashboardPath, state: { sectionEnum } });
  };

  const navigateToApply = () => {
    history.push(applyPath);
  };

  const navigateToRegistration = () => {
    history.push(registerPath);
  }

  const navigateToLogin = () => {
    history.push(loginPath);
  }

  const refresh = () => {
    history.go(0);
  };

  return {
    navigateToResults,
    navigateToStudySpace,
    navigateToDashboard,
    navigateToContinueQuiz,
    navigateToMembership,
    navigateToReadiness,
    navigateToMembershipUnconditional,
    navigateToResultsAnswers,
    navigateToBaseLineTest,
    navigateToNurseGPT,
    navigateToHome,
    navigateToApply,
    navigateToRegistration,
    navigateToLogin,
    refresh,
  };
};

export default useNavigations;
