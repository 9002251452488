import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useDispatch from './useDispatch';
import { actions, webSessionAuthSelectors } from '../webSessionAuth/redux/slice';

const useWebSessionAuth = () => {
  const { dispatch } = useDispatch();
  const { webSession, loading, errors } = useSelector(webSessionAuthSelectors.allState);

  useEffect(() => {
    if (!webSession) {
      dispatch(actions.createWebSessionRequest());
    }
  }, [webSession]);

  return {
    webSession,
    errors,
    loading,
  };
};

export default useWebSessionAuth;
