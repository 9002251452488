import { ChangeEvent } from 'react';
import { EContentTypes, IChoice, IQuestionAnswers } from '../../types';
import {
  Choice,
  ChoicesContainer,
  ChoiceExplanation,
  ChoiceExplanationContainer,
  ChoiceContainer,
  ChoiceHeader,
  IconContainer,
  ChoiceSelectionContainer,
} from './styled';
import TemplateRenderer from './templates';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import { ReactComponent as CheckMark } from '../../../images/icons/checkMark.svg';
import { ReactComponent as CloseIcon } from '../../../images/icons/incorrect.svg';
import { en } from '../../../i18n';
import { SINGLE_CHOICE_SELECT_ALL_QUESTIONS } from '../../constants';

const { youSelected, answerTitle, correctAnswerTitle, rationale } = en.smartQuiz;

interface IProps {
  questionId: string;
  choices?: IChoice[];
  correctAnswers?: string[];
  onChoose?: (answer: string | string[]) => void;
  showExplanations: boolean;
  answer?: IQuestionAnswers;
}

const Choices = ({ questionId, choices, correctAnswers = [], onChoose, showExplanations, answer }: IProps) => {
  if (!choices || !Array.isArray(choices) || !Array.isArray(correctAnswers)) return null;
  const isMultiSelect = correctAnswers.length > 1 || SINGLE_CHOICE_SELECT_ALL_QUESTIONS.includes(questionId);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChoose?.(event.target.value);
  };

  const changeCheckbox = (id: string, isChecked: boolean) => {
    const { value = [] } = answer || {};
    const newValue = [...value];

    if (isChecked) newValue.push(id);
    else {
      const elementIndex = newValue.indexOf(id);
      newValue.splice(elementIndex, 1);
    }

    onChoose?.(newValue);
  };

  const onChangeCheckbox = ({ target }: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
    changeCheckbox(target.value, isChecked);
  };

  const onClickRadioChoice = (id: string) => () => {
    onChoose?.(id);
  };

  const onClickMultiChoice = (id: string) => () => {
    const { value } = answer || {};
    const checked = !!value?.includes(id);
    changeCheckbox(id, !checked);
  };

  const renderChoices = () =>
    choices.map((choice, index) => {
      const { content, id } = choice;
      const { explanation } = content;
      const Icon = correctAnswers.includes(id) ? CheckMark : CloseIcon;
      const { value } = answer || {};

      const isSelectedChoice = () => {
        if (!value) return false;
        if (Array.isArray(value)) return value.includes(id);
        return value === id;
      };

      const icon = (
        <IconContainer>
          <Icon />
        </IconContainer>
      );

      if (!showExplanations)
        return (
          <div key={index}>
            <Choice onClick={isMultiSelect ? onClickMultiChoice(id) : onClickRadioChoice(id)}>
              {isMultiSelect ? (
                <Checkbox checked={!!value?.includes(id)} value={id} />
              ) : (
                <Radio checked={value === id} value={id} />
              )}
              {content.choice && <TemplateRenderer content={content.choice} />}
            </Choice>
          </div>
        );

      return (
        <ChoiceContainer key={index}>
          {isSelectedChoice() && (
            <ChoiceSelectionContainer isCorrect={correctAnswers.includes(id)}>{youSelected}</ChoiceSelectionContainer>
          )}
          <ChoiceExplanationContainer isSelected={isSelectedChoice()} isCorrect={correctAnswers.includes(id)}>
            <ChoiceHeader>
              <span>{correctAnswers.includes(id) ? correctAnswerTitle : answerTitle}</span>

              {content.choice?.type === EContentTypes.PLAIN_TEXT ? (
                <span>{content.choice?.content.text}</span>
              ) : (
                content.choice && <TemplateRenderer content={content.choice} />
              )}
            </ChoiceHeader>
            {explanation && (
              <ChoiceExplanation>
                <span>{rationale}</span>
                <TemplateRenderer width="80%" content={explanation} />
              </ChoiceExplanation>
            )}
            {icon}
          </ChoiceExplanationContainer>
        </ChoiceContainer>
      );
    });

  return <ChoicesContainer>{renderChoices()}</ChoicesContainer>;
};

export default Choices;
