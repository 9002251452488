import { IStepComponent } from '../types';
import { ChoicesContainerCommute, ChoicesContainerWorkEnv } from './styled';
import useStep from './useStep';

const WorkEnv = ({ question }: IStepComponent) => {
  const { title, renderMultipleChoices } = useStep({ question });

  return (
    <>
      {title}
      <ChoicesContainerWorkEnv>{renderMultipleChoices('radio')}</ChoicesContainerWorkEnv>
    </>
  );
};

export default WorkEnv;
