enum EQuestionIDS {
  U1 = 'u1',
  U2 = 'u2',
  U3 = 'u3',
  U4 = 'u4',
  G1 = 'g1',
  G2 = 'g2',
  G3 = 'g3',
  G4 = 'g4',
  G5 = 'g5',
  G6 = 'g6',
  G7 = 'g7',
  G8 = 'g8',
  G9 = 'g9',
}

type THeaderBannerQuestionResponse = {
  [key in EQuestionIDS]: number;
};

interface ISendQuestionResponse {
  questionId: string;
  response: string;
}

export { EQuestionIDS };
export type { THeaderBannerQuestionResponse, ISendQuestionResponse };
