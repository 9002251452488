import { IPlainText } from '../../../types';
import { TextContainer } from '../styled';

interface IProps {
  content: IPlainText;
  width?: string;
  fullWidthMobile?: boolean;
}

const PlainText = ({ content, width, fullWidthMobile }: IProps) => {
  const { text } = content;
  return (
    <TextContainer fullWidthMobile={fullWidthMobile} width={width}>
      <p>{text}</p>
    </TextContainer>
  );
};

export default PlainText;
