const configObject = {
  awsCognito: {
    aws_project_region: 'us-east-2',
    aws_user_pools_id: 'us-east-2_VvhFYoUZK',
    aws_user_pools_web_client_id: '5u5b6j485h7l0cs1c9gbef3pdc',
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    oauth: {
      domain: 'auth.app.smartn.ca',
      scope: ['email', 'openid', 'profile'],
      responseType: 'code',
      redirectSignIn: 'https://app.smartn.ca',
      redirectSignOut: 'https://app.smartn.ca',
      redirect_ui: 'https://app.smartn.ca',
    },
    federationTarget: 'COGNITO_USER_POOLS',
  },
  endpointV2: 'https://my-content.api.smartn.ca',
  smartnEndpoint: 'https://groups.api.smartn.ca',
  quizzingEndpoint: 'https://quizzing-v2.api.smartn.ca',
  enableNotifications: false,
  billingEndpoint: 'https://billing.api.smartn.ca',
  nurseGPTEndpoint: 'https://ask-v2.api.smartn.ca',
  nurseGPTWebSocketEndpoint: 'wss://ask-ws.api.smartn.ca',
  publicNurseGPTWebSocketEndpoint: 'wss://ask-ws.public.api.smartn.ca',
  webSessionAuthEndpoint: 'https://sessions.public.api.smartn.ca',
  notificationDetails: {
    demoVideoId: '',
  },
  stripePlans: {
    essentials: { priceId: 'price_1NGRwbHuHOUUyqEXHDYrlVYJ', productId: 'prod_NF18CawjT3sEIw' },
    allAccess: { priceId: 'price_1MQuhAGBajDEZrqyFw6ReEiY', productId: '' },
    smartn30Days: { priceId: 'price_1NEdT5HuHOUUyqEXnJPZwmF8', productId: 'prod_NrE9bIJHEicKry' },
    smartn90Days: { priceId: 'price_1NEdVjHuHOUUyqEXWM8yvnUP', productId: 'prod_NrECGzea4HIUCr' },
    basicAccess: { priceId: 'price_1OJPURHuHOUUyqEXdRyCpNQv', productId: 'prod_P7eo8D32Hh90c3' },
    smartCLEXAddon: { priceId: 'price_1P70yrHuHOUUyqEXy9iQuSZn', productId: 'prod_P85ADcc0gHHKP8' },
    sponsoredBasicAccess: { priceId: 'price_1OhwUXHuHOUUyqEXWrdFHpQo', productId: 'prod_P7eo8D32Hh90c3' },
  },

  stripePublishableKey:
    'pk_live_51HtawRHuHOUUyqEXZBaGniMvB5ry0FQ59ztg7CjxJYi87G73fSCk7ybj34OEEbImizJjUOfLxbuz0t36fOnbar7J00BU8Ur4fD',
  allowedTrialQuizzes: '2',
};

const handleProdConfig = () => {
  if (window.config?.useProdConfig === 'true') {
    console.log('using prodConfig', configObject);
    window['config'] = configObject;
  }
};

export { handleProdConfig };

export default configObject;
