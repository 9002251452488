import Button from '../../components/Button';
import { ModalOverlay, ModalContainer, ModalTitle, AboutModalBody } from '../styled';
import { en } from '../../../i18n';

const { trouble, somethingWrong, refreshButton } = en.nurseGPT;

const ErrorModal = ({ refresh }: { refresh: () => void }) => {
  return (
    <ModalOverlay zIndex={12}>
      <ModalContainer>
        <ModalTitle>{somethingWrong}</ModalTitle>
        <AboutModalBody>{trouble}</AboutModalBody>
        <Button variant="primary" onClick={refresh}>
          {refreshButton}
        </Button>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default ErrorModal;
