import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AppContextProvider } from './AppContext';
import { Provider } from 'react-redux';
import store from './v2/redux/store';
import interceptor from './v2/axios/interceptor';
import ProductionDomain from './v2/classes/Domains';
import { appIds } from './constants';
import './index.css';
import ErrorBoundary from './v2/ErrorBoundary';

if (ProductionDomain.isProductionDomain()) TagManager.initialize({ gtmId: appIds.googleTags });
interceptor.run();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ErrorBoundary>
          <AppContextProvider>
            <App />
          </AppContextProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
