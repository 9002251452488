import { styled } from '@mui/material/styles';
import Button from '../components/Button';
import { colors } from '../../themes';
import { shouldForwardProps } from '../utils';
import { scrollbar, Tag, widthInAnimation } from '../sharedStyled';
import SearchIcon from '@mui/icons-material/Search';

interface IBannerProps {
  leftPadding?: string;
  bottom?: boolean;
  hasChangeTopics?: boolean;
  centerButtons?: boolean;
}

interface IPastQuizCardMenuProps {
  open?: boolean;
}

interface ISearchIconProps {
  isSearchOpen?: boolean;
}

interface IPlacementProps {
  isPlacement?: boolean;
  isCustom?: boolean;
}

interface ISmartQuizContainerProps {
  progress?: number;
  isCaseStudy?: boolean;
}

const commonContainerStyles = `
  border: 1px solid rgba(226, 226, 231, 1);
  background: ${colors.lightestGrey};
  min-height: 70vh;
  border-radius: 16px;
  width:100%;
  position: relative;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  max-width: max(1440px, 50vw);
  margin-left:auto;
  margin-right:auto;
  
`;

const EmptySmartQuizContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isPlacement', 'isCustom']),
})<IPlacementProps>(
  ({ theme, isPlacement, isCustom }) => `
  ${commonContainerStyles}
  //padding-bottom: 120px;
  ${theme.breakpoints.down('sm')} {
    svg {
      max-width: 100%;
    }
  }
  ${
    isPlacement
      ? `
    margin-top: 0;
    display: flex;
    align-items: center;
    padding-bottom: 0;
  `
      : ''
  }
  ${
    isCustom
      ? `
    display:flex;
    flex-direction:column;
    
    `
      : ''
  }
`,
);

const SmartQuizContainer = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['progress', 'isCaseStudy']),
})<ISmartQuizContainerProps>(
  ({ theme, progress, isCaseStudy }) => `
  ${commonContainerStyles}
  ${isCaseStudy ? 'min-height: unset;' : ''}
  ${theme.breakpoints.down('sm')} {
    min-height:80vh;
    svg {
    }
  }
  > div:nth-child(1) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    span {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      ${progress === 100 ? '' : 'border-top-right-radius: 0;'}
    }
  }
  display:flex;
  flex-direction:column;
`,
);

const ContentContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 30%;
  ${props => props.theme.breakpoints.down('md')} {
    padding: 2% 10%;
  }
  margin-top: ${props => props.theme.spacing(2)};
`;

const LogoTextContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${props => props.theme.spacing(2)};
  gap: ${props => props.theme.spacing(1)};
  align-items: center;
  img {
    width: 28px;
    border-radius: 4px;
  }
`;

const Text = styled('span')`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 32px;
  .text-div {
    margin-bottom: ${props => props.theme.spacing(2)};
  }
`;

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  margin-top: ${props => props.theme.spacing(3)};
  gap: 18px;
`;

const PaddedButtonContainer = styled(ButtonContainer)`
  margin-bottom: ${props => props.theme.spacing(3)};
`;

const Banner = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['leftPadding', 'bottom', 'hasChangeTopics', 'centerButtons']),
})<IBannerProps>(
  ({ theme, leftPadding, bottom, hasChangeTopics, centerButtons }) => `
  box-sizing: border-box;
  width: 100%;
  height: 88px;
  background: ${colors.mediumLightGray};
  border: 1px solid ${colors.lightGrey};
  padding: 12px 32px 12px ${leftPadding || '32px'};
  display: flex;
  align-items: center;

  ${
    hasChangeTopics
      ? `
  position: relative;
  button {
    padding: 6.5px 16px;
  }
  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
    gap: 10px;
    height: 110px;
    
  }
  ${theme.breakpoints.down('$xs')} {
    height:130px;
  }
  `
      : `
  button {
    margin-right: ${theme.spacing(2.5)};
  }
  `
  }
  ${theme.breakpoints.between('sm', 'md')} {
    padding: 12px 10%;
  }
  ${theme.breakpoints.down('sm')} {
    padding: 0;
    display: flex;
    justify-content: center;
    gap: ${theme.spacing(2)};
    padding-left: 10%;
    padding-right:10%;
    button {
      margin-right:0;
    }

    ${theme.breakpoints.down('$xxs')} {
      height:auto;
      min-height:88px;
      flex-wrap:wrap;
      padding-top:10%;
      padding-bottom:10%;
      button {
        width:100%;
      }
    }
  }

  ${theme.breakpoints.up('md')} {
    padding: 12px 32px 12px ${leftPadding || '32px'};
  }

  ${theme.breakpoints.up('lg')} {
    padding:  12px 32px 12px ${leftPadding || '32px'};
  }
  ${
    bottom
      ? `
      // position:absolute;
      // bottom:0;
      margin-top: auto;
      border: none;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;`
      : ''
  }
  ${
    centerButtons
      ? `
      display: flex;
      justify-content: center;
    `
      : ''
  }
`,
);

const ImageContainer = styled('div')`
  text-align: center;
  ${props => props.theme.breakpoints.down('$xxs')} {
    svg {
      width: 80%;
    }
  }
`;

const PastQuizzesWrapper = styled('div')`
  margin: 50px 12px 0 12px;
  max-height: 70vh;
  overflow-y: auto;
  ${scrollbar}
`;

const PastQuizzesContainer = styled('div')`
  width: 54%;
  max-width: 776px;
  margin: auto;
  ${props => props.theme.breakpoints.down('md')} {
    width: 90%;
    max-width: unset;
  }
`;

const PastQuizzesHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 27px;
  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    gap: 20px;
  }
`;

const PastQuizzesTitle = styled('h3')`
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
`;

const PastQuizzesSubtitle = styled('div')`
  font-size: 1rem;
  font-weight: 500;
  margin-top: 10px;
`;

const PastQuizCard = styled('div')`
  position: relative;
  background-color: ${colors.white};
  border: 1px solid ${colors.lightGrey};
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 24px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
`;

const PastQuizCardTitle = styled('div')`
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 8px;
  span {
    font-size: 0.875rem;
    color: ${colors.red};
    font-style: italic;
    margin-left: 10px;
  }
`;

const PastQuizCardDate = styled('div')`
  font-size: 0.875rem;
  color: ${colors.darkGrey};
  font-weight: 600;
`;

const PastQuizCardResult = styled('div')`
  font-size: 1rem;
  font-weight: 700;
  color: ${colors.black};
  margin-top: 18px;
  margin-bottom: 12px;
  span {
    color: ${colors.purple30};
  }
`;

const PastQuizCardTagsContainer = styled('div')`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 8px;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const PastQuizMenuButton = styled(Button)`
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 16px;
  right: 16px;
  box-shadow: none;
  border: none;
  border-radius: 4px;
  :hover {
    background-color: ${colors.purple5};
  }
  :focus {
    background-color: ${colors.purple5};
  }
  & span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const PastQuizCardMenu = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['open']),
})<IPastQuizCardMenuProps>(
  ({ open }) => `
  padding: 8px 0px;
  border-radius: 4px;
  position: absolute;
  top: 64px;
  right: 12px;
  box-shadow: 0px 2px 3px rgb(0 0 0 / 20%);
  background-color: ${colors.white};
  width: 150px;
  max-width: 264px;
  display: ${open ? 'block' : 'none'};
  svg {
    right: 12px;
    position: absolute;
    filter: drop-shadow(0px 2px 3px rgb(0 0 0 / 20%));
    transform: translateY(-100%);
    top: 0;
    filter: drop-shadow(0px -1px 1px rgb(0 0 0 / 7%));
  }
  p {
    white-space: break-spaces;

  }
  span {
    color:${colors.primary};
  }
  z-index:20;
`,
);

const PastQuizCardMenuItem = styled('div')`
  font-size: 1rem;
  color: ${colors.black};
  font-weight: 600;
  white-space: nowrap;
  :hover {
    background-color: ${colors.lightestGrey};
  }
  padding: 8px 16px;
  cursor: pointer;
`;

const ReviewResultsOverlay = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(24, 24, 24, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
`;

const ReviewResultsModal = styled('div')`
  width: 85%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${colors.lightestGrey};
  padding: 0 59.5px;
  border-radius: 16px;
  ${props => props.theme.breakpoints.down('sm')} {
    width: 85%;
    padding: 0 4.5%;
  }
  > div:nth-child(1) {
    position: static;
    border-radius: 16px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    padding: 24px 119px;
    width: calc(100% - 120px);
    ${props => props.theme.breakpoints.down('sm')} {
      padding: 12px 5%;
    }
  }
  > div:nth-child(2) {
    background-color: ${colors.lightestGrey};
    margin-right: 12px;
    margin-top: 40px;
    margin-bottom: 24px;
    padding: 40px 120px;
    padding-top: 0;
    width: calc(100% - 131px);
    ${props => props.theme.breakpoints.down('sm')} {
      padding: 12px 5%;
      margin-top: 24px;
    }
    > div {
      > div {
        padding: 0;
      }
    }
  }
`;

const ReviewResultsFooter = styled('div')`
  width: 100%;
  background-color: ${colors.mediumLightGray};
  padding: 20px 59px;
  border: 1px solid ${colors.lightGrey};
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  justify-content: center;
  ${props => props.theme.breakpoints.down('sm')} {
    padding: 20px 16px;
  }
`;

const FiltersModalOverlay = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  background-color: rgba(24, 24, 24, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FiltersModalContainer = styled('div')`
  width: 38%;
  max-width: 560px;
  background-color: ${colors.white};
  border-radius: 16px;
  padding: 40px;
  ${props => props.theme.breakpoints.down('sm')} {
    width: 70%;
  }
`;

const FiltersModalTitle = styled('div')`
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 40px;
  text-align: center;
`;

const FiltersModalBody = styled('div')`
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 40px;
`;

const FiltersModalButtons = styled('div')`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const StartQuizButton = styled(Button, {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isPlacement']),
})<IPlacementProps>(
  ({ isPlacement }) => `
  && {
    margin-right: auto;
  }
  margin: auto;
  ${
    isPlacement
      ? `
    margin: unset;
    && {
      margin-right: unset;
    }
  `
      : ''
  }
`,
);

const StartQuizButtonContainer = styled('span')`
  margin: auto;
`;

const CustomizeQuizTitle = styled('div')`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${colors.black10};
  margin: auto;
  margin-top: 40px;
  width: fit-content;
`;

const CustomizeQuizSubtitle = styled('div')`
  font-size: 1.125rem;
  font-weight: 700;
  color: ${colors.black10};
  span {
    font-size: 1rem;
    color: ${colors.grey400};
    font-weight: 500;
  }
  margin: 56px 0 16px 48px;
  ${props => props.theme.breakpoints.down('sm')} {
    margin-left: 18px;
  }
`;

const CustomizeQuizSelectionContainer = styled('div')`
  margin: 0 48px;
  display: flex;
  gap: 24px;
  margin-bottom: 30px;
  ${props => props.theme.breakpoints.down('md')} {
    margin: 0 67px;
    margin-bottom: 30px;
    flex-direction: column;
    align-items: center;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    margin: 0 35px;
    margin-bottom: 30px;
  }
  > div {
    width: 100%;
    background-color: ${colors.white};
    border-radius: 8px;
    padding: 24px 12px 0 24px;
  }
`;

const TopicsHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  span:nth-child(1) {
    font-size: 1.25rem;
    font-weight: 700;
    color: ${colors.black10};
  }
  span:nth-child(2) {
    font-size: 1rem;
    color: ${colors.charcoal};
    margin-right: 12px;
  }
`;

const TopicsSubtitle = styled('div')`
  font-size: 1rem;
  color: ${colors.black10};
`;

const TopicChoicesContainer = styled('div')`
  margin-top: 21px;
  height: 300px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  ${scrollbar}
`;

const TopicChoice = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  color: ${colors.black10};
  display: flex;
  gap: 12px;
  align-items: center;
  p {
    margin: 0;
    &::first-letter {
      text-transform: capitalize;
    }
  }
  span {
    transition: 0.3s background ease;
    padding: 0;
  }
`;

const SelectionButtons = styled('div')`
  display: flex;
  align-items: center;
  gap: 32px;
  margin-right: 40px;
  button {
    font-size: 0.875rem;
  }
  ${props => props.theme.breakpoints.down('xmd')} {
    margin-right: 0;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    gap: 10px;
  }
`;

const TopicsControl = styled('div', {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isSearchOpen']),
})<ISearchIconProps>(
  ({ isSearchOpen }) => `
  display: flex;
  align-items: center;
  gap: 7px;
  margin-top: 33px;
  ${!isSearchOpen ? 'justify-content: space-between;' : ''}
`,
);

const SearchIconStyled = styled(SearchIcon, {
  shouldForwardProp: prop => shouldForwardProps(prop, ['isSearchOpen']),
})<ISearchIconProps>(
  ({ isSearchOpen }) => `
  color: ${colors.purple30};
  border-radius: 4px;
  padding: 2px;
  :hover {
    background-color: ${colors.purple5};
    cursor: pointer;
  }
  ${isSearchOpen ? `background-color: ${colors.purple5};` : ''}
`,
);

const SearchBar = styled('div')`
  padding: 8px 16px;
  border: 2px solid ${colors.purple30};
  border-radius: 8px;
  width: 100%;
  margin-right: 40px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  button {
    font-size: 0.875rem;
  }
  ${widthInAnimation(100, 0.5, 'linear')}
  ${props => props.theme.breakpoints.down('xmd')} {
    margin-right: 0;
  }
`;

const SearchInput = styled('input')`
  padding: 0;
  width: 100%;
  font-size: 1rem;
  border: none;
  outline: none;
  color: ${colors.black10};
  ::placeholder {
    color: ${colors.mediumGray};
  }
`;

const NoTopicsMessage = styled('div')`
  width: 77%;
  font-size: 0.875rem;
  color: ${colors.black10};
  border-radius: 4px;
  background-color: ${colors.lightestGrey};
  padding: 21px 12%;
  text-align: center;
  margin: 120px auto;
  box-sizing: border-box;
`;

const CategoriesContainer = styled('div')`
  margin-top: 40px;
`;

const TopicTitle = styled('div')`
  font-size: 1rem;
  font-weight: 700;
  color: ${colors.black10};
  margin-bottom: 8px;
`;

const CategoriesTags = styled('div')`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  div {
    margin: 0 12px 8px 0;
  }
`;

const NoCategoriesMessage = styled('div')`
  font-size: 0.875rem;
  color: ${colors.darkGrey};
`;

const CategoriesList = styled('div')`
  margin-top: 40px;
  height: 342px;
  overflow-y: auto;
  ${scrollbar}
  overflow-x: hidden;
  > div:nth-child(1) {
    margin-top: 0;
  }
`;

const CustomizeQuizHeading = styled('div')`
  font-size: 1rem;
  font-weight: 500;
  color: ${colors.black10};
  margin: auto;
  width: 80%;
  margin-top: 10px;
  text-align: center;
`;

const CustomizeQuizTypesContainer = styled('div')`
  display: flex;
  gap: 32px;
  margin: 24px 48px 0 48px;
  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    margin-left: 18px;
    gap: 16px;
    div {
      span {
        flex-shrink: 0;
      }
    }
  }
  div {
    display: flex;
    gap: 12px;
    align-items: center;
    span {
      padding: 0;
    }
  }
`;

const PastQuizzesTag = styled(Tag)`
  margin-right: 0;
  white-space: normal;
  max-width: 100%;
  word-break: break-all;
  ${props => props.theme.breakpoints.down('sm')} {
    padding: 4px 10px;
  }
`;

const TopBanner = styled(Banner)`
  ${props => props.theme.breakpoints.down('$md')} {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    flex-direction: column;
    gap: 10px;
  }

  ${props => props.theme.breakpoints.between('sm', 'md')} {
    padding: 12px 32px;
    justify-content: space-between;
  }
`;

const ExitButtonContainer = styled('div')`
  button {
    color: ${colors.errorRed};
    margin-right: 0;
  }

  margin-right: 0;
  margin-left: auto;

  ${props => props.theme.breakpoints.down('$sm')} {
    height: 100%;
    button {
      height: 100%;
      z-index: 21474836000;
    }
  }
  ${props => props.theme.breakpoints.down('$xxs')} {
    margin-right: auto;
  }
`;

const PastQuizzesShowMoreButton = styled(Button)`
  display: block;
  margin: auto;
  margin-bottom: 24px;
`;

export {
  EmptySmartQuizContainer,
  SmartQuizContainer,
  ContentContainer,
  LogoTextContainer,
  Text,
  ButtonContainer,
  Banner,
  ImageContainer,
  PastQuizzesContainer,
  PastQuizzesTitle,
  PastQuizCard,
  PastQuizCardTitle,
  PastQuizCardDate,
  PastQuizCardResult,
  PastQuizCardTagsContainer,
  PastQuizMenuButton,
  PastQuizCardMenu,
  PastQuizCardMenuItem,
  ReviewResultsOverlay,
  ReviewResultsModal,
  PastQuizzesHeader,
  ReviewResultsFooter,
  PastQuizzesWrapper,
  FiltersModalOverlay,
  FiltersModalContainer,
  FiltersModalBody,
  FiltersModalButtons,
  FiltersModalTitle,
  StartQuizButton,
  CustomizeQuizTitle,
  CustomizeQuizSubtitle,
  CustomizeQuizSelectionContainer,
  TopicsHeader,
  TopicsSubtitle,
  TopicChoicesContainer,
  TopicChoice,
  SelectionButtons,
  TopicsControl,
  SearchIconStyled,
  SearchBar,
  SearchInput,
  StartQuizButtonContainer,
  NoTopicsMessage,
  CategoriesContainer,
  TopicTitle,
  CategoriesTags,
  NoCategoriesMessage,
  CategoriesList,
  CustomizeQuizHeading,
  PastQuizzesSubtitle,
  CustomizeQuizTypesContainer,
  PastQuizzesTag,
  TopBanner,
  ExitButtonContainer,
  PastQuizzesShowMoreButton,
  PaddedButtonContainer,
};
