import { Button } from '@mui/material';
import { exit } from 'process';
import { ContinueQuizContent, ContinueQuizLeftContent } from '../Home/PracticeQuiz/styled';
import { ExitCardButtonContainer, ExitQuizCard as ExitQuizCardStyled } from './steps/styled';

interface IExitQuizCard {
  Icon: React.ComponentType<any>;
  title: string;
  subTitle: string;
  action: JSX.Element;
}

const ExitQuizCard = ({ Icon, title, subTitle, action }: IExitQuizCard) => {
  return (
    <ExitQuizCardStyled applyFlex hideCursor>
      <ContinueQuizContent>
        <ContinueQuizLeftContent>
          <div>
            <Icon />
          </div>
          <div>
            <h2>{title}</h2>
            <span>{subTitle}</span>
          </div>
        </ContinueQuizLeftContent>

        <ExitCardButtonContainer className="button-container">{action}</ExitCardButtonContainer>
      </ContinueQuizContent>
    </ExitQuizCardStyled>
  );
};

export default ExitQuizCard;
