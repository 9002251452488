import { useSelector } from 'react-redux';
import { useDispatch } from '../hooks';
import { actions, applySelector } from './redux/slice';
import { APPLY_EXTRA_QUESTION, HARD_CODED_QUIZ_ID } from './constants';
import useAttempts from '../hooks/useAttempts';
import { useEffect, useRef } from 'react';
import useSteps, { allRequiredSteps, IStep, questionData } from './useSteps';
import { EApplyTypes } from '../types';

interface IUseApply {
  fetchAttempts?: boolean;
}

const useApply = ({ fetchAttempts = false }: IUseApply) => {
  const { dispatch } = useDispatch();

  const {
    loading: loadingApply,
    loadingStartQuiz,
    questionAnswers,
    loadingAnswer,
    currentQuestionIndex: stepIndex,
    loadingFetchEnhance,
    currentType,
  } = useSelector(applySelector.allState);

  const loading = loadingApply || loadingFetchEnhance;

  const { currentApplyAttempt, currentApplyEnhancementAttempt, applyQuizState } = useAttempts();

  const { renderStep, currentStep, getCurrentQuestion, getSteps } = useSteps({
    index: stepIndex,
    attempt: currentType === EApplyTypes.ENHANCE ? currentApplyEnhancementAttempt : currentApplyAttempt,
  });

  const isProgressChecked = useRef(false);

  useEffect(() => {
    if (!fetchAttempts) return;
    if (isProgressChecked.current) return;
    if (!currentApplyAttempt) return;
    isProgressChecked.current = true;
    let lastDoneQuestionId = '';
    let isDone = true;
    const isSubmitted = questionAnswers[APPLY_EXTRA_QUESTION]?.response;

    allRequiredSteps.forEach((step, index) => {
      const isCurrentQuestionDisabled = isQuestionDisabled(step);
      isDone = isDone && !isCurrentQuestionDisabled;
      if (
        !isCurrentQuestionDisabled &&
        ![questionData.certification.id, questionData.uploadResume.id].includes(step.id)
      ) {
        lastDoneQuestionId = step.id;
      }
    });

    if (isDone || isSubmitted) {
      dispatch(actions.setCurrentQuestionIndex({ index: -1 }));
      return;
    }

    const findAllIndicesById = (arr: IStep[], id: string): number[] =>
      arr.reduce((indices: number[], item: IStep, index: number) => {
        if (item.id === id) indices.push(index);

        return indices;
      }, []);

    if (lastDoneQuestionId === questionData.license.id) lastDoneQuestionId = questionData.uploadResume.id;

    if (lastDoneQuestionId) {
      const steps = getSteps();
      const indices = findAllIndicesById(steps, lastDoneQuestionId).sort((a, b) => b - a);
      if (indices.length > 0) {
        const targetIndex = indices[0] + 1;

        if (targetIndex < steps.length) {
          dispatch(actions.setCurrentQuestionIndex({ index: targetIndex }));
        }
      }
    }
  }, [currentApplyAttempt]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [stepIndex]);
  const startQuiz = () => {
    dispatch(actions.attemptQuiz({ quizId: HARD_CODED_QUIZ_ID }));
  };

  const isQuestionDisabled = (step: IStep, isLive?: boolean) => {
    const { id } = step;
    const currentResponse = questionAnswers?.[id]?.response;
    if (step.checkDisabled) {
      const stepDisabled = step.checkDisabled(currentResponse, getCurrentQuestion(id));
      const liveDisabled = step.checkDisabledLive?.(currentResponse, getCurrentQuestion(id));
      if (!isLive) return stepDisabled;
      return stepDisabled || liveDisabled;
    }
    if (Array.isArray(currentResponse)) return currentResponse.length === 0;
    return currentResponse === undefined || currentResponse === '';
  };

  const isNextDisabled = () => isQuestionDisabled(currentStep, true);

  const goNext = () => {
    if (isNextDisabled()) return;
    const currentResponse = questionAnswers?.[currentStep?.id]?.response;
    const attemptId =
      currentType === EApplyTypes.ENHANCE ? currentApplyEnhancementAttempt?.attemptId : currentApplyAttempt?.attemptId;

    const handleFileUploads = () => {
      const isFile = currentResponse instanceof File;
      if (isFile) {
        dispatch(
          actions.uploadRequest({
            questionId: questionData.uploadResume.id,
            file: currentResponse,
            attemptId: attemptId || '',
          }),
        );
        return;
      }

      if (currentResponse === null) {
        dispatch(
          actions.submitAnswer({
            attemptId: attemptId || '',
            questionId: currentStep?.id || '',
            response: currentResponse,
            noSucessSideEffects: !!currentStep.extraSubmitId,
          }),
        );
        return;
      }
      if (!currentStep.extraSubmitId) dispatch(actions.incrementQuestionIndex());
    };

    const handleSubmit = (overrideId?: string, noSideEffects?: boolean) => {
      dispatch(
        actions.submitAnswer({
          attemptId: attemptId || '',
          questionId: overrideId || currentStep?.id || '',
          response: true,
          noSucessSideEffects: noSideEffects,
        }),
      );
      dispatch(actions.setQuestionAnswer({ questionId: overrideId || currentStep?.id || '', response: true }));
      return;
    };

    if (currentStep?.id === questionData.uploadResume.id) {
      handleFileUploads();

      if (currentStep.extraSubmitId) handleSubmit(currentStep.extraSubmitId, currentResponse instanceof File);
      return;
    }
    if (currentStep?.isSubmit) {
      handleSubmit();
      return;
    }

    if (currentStep?.isSubmit) {
      handleSubmit();
      return;
    }

    if (!currentResponse || currentStep?.skipSubmitAnswer) {
      dispatch(actions.incrementQuestionIndex());
      return;
    }

    dispatch(
      actions.submitAnswer({
        attemptId: attemptId || '',
        questionId: currentStep?.id || '',
        response: currentStep?.isSubmit ? true : currentResponse,
      }),
    );
  };

  const goPrevious = () => {
    dispatch(actions.decrementQuestionIndex());
  };

  const getTotalQuestions = () => {
    const stepsLength = getSteps();
    return stepsLength.length - 2;
  };

  const totalQuestions = getTotalQuestions() || 1;

  const quizProgress = Math.round((stepIndex / totalQuestions) * 100);
  // useEffect(() => {
  //   // if (!currentApplyAttempt && fetchAttempts) dispatch(actions.fetchApplyAttempts());
  //   //if (!currentApplyEnhancementAttempt && fetchAttempts) dispatch(actions.fetchApplyEnahcnementAttempts());
  //   // if (currentApplyAttempt && fetchAttempts && !currentApplyAttempt.questionsTransformed)
  //   //   dispatch(actions.transformAttemptRequest({ attempt: currentApplyAttempt }));
  // }, [currentApplyAttempt, currentApplyEnhancementAttempt]);

  const totalStepLength = getSteps().length;

  return {
    control: {
      startQuiz,
      goNext,
      goPrevious,
      renderStep,
    },
    state: {
      loading,
      loadingStartQuiz,
      currentApplyAttempt,
      stepIndex,
      quizProgress,
      totalQuestions,
      currentStep,
      isNextDisabled: isNextDisabled(),
      loadingAnswer,
      applyQuizState,
      currentType,
      totalStepLength,
    },
  };
};

export default useApply;
