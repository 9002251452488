import { EAuthResposneCodes, IAuthError } from '../../types';
import BaseTransformer from './BaseTransformer';
import { en } from '../../../i18n';

const { signUp } = en;

class AuthErrorTransformer extends BaseTransformer {
  public transformOut(data: any): { errors: IAuthError } {
    const { code: responseCode, message, name: responseCodeName } = data;

    let errorMessage = message;
    switch (responseCode) {
      case EAuthResposneCodes.emailAlreadyExists:
        errorMessage = signUp.userExistsError;
    }
    return {
      errors: {
        message: errorMessage,
        responseCode,
        responseCodeName,
      },
    };
  }
}

export default AuthErrorTransformer;
