import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useRef, useState } from 'react';
import {
  AccordionContainer,
  AccordionDetailsContent,
  AccordionDetailsTitle,
  AccordionHeader,
  DescriptionContainer,
  DisclaimerSubText,
  ExampleContainer,
  ExampleDetails,
  ExampleTitle,
  PositiveSamplesSeperator,
} from './styled';
import { en } from '../../../../i18n';

const {
  nurseGPT: { tipsPopup },
} = en;

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }),
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const ControlledAccordion = () => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const headerAccordionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const instructionAccordionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const scrollToHeaderAccordion = (index: number) => {
    const element = headerAccordionRefs.current[index];
    if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const scrollToInstructionsAccordion = (index: number) => {
    const element = instructionAccordionRefs.current[index];
    if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const renderHeaderAccordions = () => {
    return tipsPopup.headerAccordions.map((item, index) => {
      const renderAccordionDetails = () => {
        return item.points.map((point, ptIndex) => {
          return (
            <AccordionDetailsContent key={ptIndex}>
              <AccordionDetailsTitle>{point.title}</AccordionDetailsTitle>
              {item.withExamples ? (
                <ExampleContainer isError={false}>
                  <ExampleTitle>{tipsPopup.exampleTitle}</ExampleTitle>
                  <ExampleDetails>“{point.example}”</ExampleDetails>
                </ExampleContainer>
              ) : (
                <DescriptionContainer>
                  <DescriptionContainer>{point.example}</DescriptionContainer>
                </DescriptionContainer>
              )}
            </AccordionDetailsContent>
          );
        });
      };
      return (
        <div key={item.key} ref={el => (headerAccordionRefs.current[index] = el)}>
          <Accordion
            expanded={expanded === item.key}
            onChange={handleChange(item.key)}
            TransitionProps={{
              onEntered: () => scrollToHeaderAccordion(index), // Scroll only after expansion finishes
            }}
          >
            <AccordionSummary>
              <AccordionHeader>{item.title}</AccordionHeader>
            </AccordionSummary>
            <AccordionDetails>{renderAccordionDetails()}</AccordionDetails>
          </Accordion>
        </div>
      );
    });
  };

  const renderInstructionsAccordions = () => {
    return tipsPopup.instructionAccordions.map((item, index) => {
      const renderAccordionDetails = () => {
        const renderItemPositive = () => {
          if (!item.positiveSecond) return <>“{item.positive}”</>;
          return (
            <>
              “{item.positive}”<PositiveSamplesSeperator>{tipsPopup.exampleSeperator}</PositiveSamplesSeperator>“
              {item.positiveSecond}”
            </>
          );
        };
        return (
          <AccordionDetailsContent>
            <DescriptionContainer>{item.explaination}</DescriptionContainer>
            {item.negative && (
              <ExampleContainer isError>
                <ExampleTitle>{tipsPopup.negativeExampleTitle}</ExampleTitle>
                <ExampleDetails>“{item.negative}”</ExampleDetails>
              </ExampleContainer>
            )}

            {item.positiveB && (
              <ExampleContainer isError={false}>
                <ExampleTitle>{tipsPopup.positiveExampleTitle}</ExampleTitle>
                <ExampleDetails>
                  <>“{item.positiveB}”</>
                </ExampleDetails>
              </ExampleContainer>
            )}

            <ExampleContainer isError={false}>
              <ExampleTitle>{tipsPopup.positiveExampleTitle}</ExampleTitle>
              <ExampleDetails>{renderItemPositive()}</ExampleDetails>
            </ExampleContainer>
          </AccordionDetailsContent>
        );
      };

      return (
        <div
          style={{ overflowAnchor: 'auto' }}
          key={`${item.key}-instruction`}
          ref={el => (instructionAccordionRefs.current[index] = el)}
        >
          <Accordion
            expanded={expanded === item.key}
            onChange={handleChange(item.key)}
            TransitionProps={{
              onEntered: () => scrollToInstructionsAccordion(index), // Scroll only after expansion finishes
            }}
          >
            <AccordionSummary>
              <AccordionHeader>{item.title}</AccordionHeader>
            </AccordionSummary>
            <AccordionDetails>{renderAccordionDetails()}</AccordionDetails>
          </Accordion>
        </div>
      );
    });
  };

  return (
    <AccordionContainer>
      {renderHeaderAccordions()}

      <DisclaimerSubText>{tipsPopup.subText}</DisclaimerSubText>
      {renderInstructionsAccordions()}
      <DisclaimerSubText>{tipsPopup.disclaimer}</DisclaimerSubText>
    </AccordionContainer>
  );
};

export default ControlledAccordion;
