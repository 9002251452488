import { EQuestionIDS, ISendQuestionResponse, THeaderBannerQuestionResponse } from '../../ClicksQuiz/types';
import { headerClicksId } from '../../StudySpace/staticConstants';
import { IAttempt, ILooseObject } from '../../types';
import BaseTransformer from './BaseTransformer';

class ClicksAnswerTransformer extends BaseTransformer {
  private questionId: string;
  private storedResponse?: ILooseObject;

  public constructor(questionId: string, storedResposne?: ILooseObject) {
    super();
    this.questionId = questionId;
    this.storedResponse = storedResposne;
  }
  public transformOut(data: ISendQuestionResponse): ILooseObject {
    switch (this.questionId) {
      case headerClicksId:
        return {
          questionId: this.questionId,
          response: JSON.stringify(this.transformOutHeaderBannerResponse(data)),
        };
    }
    return {
      questionId: this.questionId,
      response: data.response,
    };
  }

  public transform(data: any) {
    return JSON.parse(data);
  }

  public transformOutHeaderBannerResponse(data: ISendQuestionResponse): THeaderBannerQuestionResponse {
    if (!this.storedResponse) {
      const response = this.constructEmptyStoredResponse();
      response[data.response as EQuestionIDS] = 1;
      return response as THeaderBannerQuestionResponse;
    }

    this.storedResponse[data.response as EQuestionIDS] += 1;

    return this.storedResponse as THeaderBannerQuestionResponse;
  }

  private constructEmptyStoredResponse(): THeaderBannerQuestionResponse {
    const payload: ILooseObject = {};
    Object.values(EQuestionIDS).forEach(key => (payload[key] = 0));
    return payload as THeaderBannerQuestionResponse;
  }

  public getRawAnswers(attempt: IAttempt): ILooseObject {
    const questionAnswers: ILooseObject = {};
    Object.entries(attempt.questionAnswers).forEach(([key, questionAnswer]) => {
      questionAnswers[key] = questionAnswer.value;
    });

    return questionAnswers;
  }
}

export default ClicksAnswerTransformer;
