import { ILooseObject } from '../types';
const HARD_CODED_QUIZ_ID = '57c57982-5497-11ef-b864-0242ac120002';
const ENHANCE_HARD_CODED_QUIZ_ID = '14753ea4-7366-11ef-b864-0242ac120002';

const APPLY_CERTIFICATIONS_ID = '04b828ca-5497-11ef-b864-0242ac120002';
const APPLY_NOTIFICATIONS_ID = '030dabb2-5497-11ef-b864-0242ac120002';
const APPLY_EXTRA_QUESTION = 'd85a2498-78e2-11ef-b864-0242ac120002';

const IGNORED_QUESTIONS_DONE = [APPLY_CERTIFICATIONS_ID, APPLY_NOTIFICATIONS_ID];
const MODAL_CLASS_NAME = 'apply-modal';

export {
  HARD_CODED_QUIZ_ID,
  ENHANCE_HARD_CODED_QUIZ_ID,
  IGNORED_QUESTIONS_DONE,
  APPLY_CERTIFICATIONS_ID,
  APPLY_EXTRA_QUESTION,
  APPLY_NOTIFICATIONS_ID,
  MODAL_CLASS_NAME,
};
