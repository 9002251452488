import { Input } from '@mui/material';
import { IArithmeticQuestionAnswer, IQuestionAnswers } from '../../types';
import { ArithmeticAnswerContainer, ArithmeticAnswerTitle } from './styled';
import TemplateRenderer from './templates';
import React from 'react';
import { ReactComponent as CheckMark } from '../../../images/icons/checkMark.svg';
import { ReactComponent as CloseIcon } from '../../../images/icons/incorrect.svg';
import { en } from '../../../i18n';

interface IArithmeticAnswerProps {
  onChange: (value: string) => void;
  correctAnswer?: IArithmeticQuestionAnswer;
  showExplanations: boolean;
  currentAnswer?: IQuestionAnswers;
}

const ArithmeticAnswer = ({ onChange, correctAnswer, showExplanations, currentAnswer }: IArithmeticAnswerProps) => {
  const onChangeInput = (e: React.BaseSyntheticEvent) => {
    const number = e?.target?.value;
    if (number !== undefined) {
      const numberString = `${number}`;
      const parsedFloat = parseFloat(numberString); //handing backend issue with leading 0s
      if (isNaN(parsedFloat)) {
        //@ts-ignore
        onChange(undefined);
      } else onChange(`${parsedFloat}`);
    }
  };

  const renderSuffix = () => {
    if (!correctAnswer?.suffix) return null;
    return <TemplateRenderer content={correctAnswer.suffix} width="auto" />;
  };

  const isCorrectAnswer = () => {
    if (currentAnswer?.isDone === undefined || !correctAnswer || currentAnswer.value === undefined) return undefined;
    const correct = +(correctAnswer?.content || '0');
    return +currentAnswer.value === correct;
  };

  const renderIcon = () => {
    const isCorrect = isCorrectAnswer();
    if (!showExplanations || isCorrect === undefined) return null;
    const Icon = isCorrect ? CheckMark : CloseIcon;
    return <Icon />;
  };

  return (
    <ArithmeticAnswerContainer>
      <ArithmeticAnswerTitle>{en.smartQuiz.arithmeticAnswerTitle}</ArithmeticAnswerTitle>
      <Input
        value={showExplanations ? currentAnswer?.value : undefined}
        disabled={showExplanations}
        renderSuffix={renderSuffix}
        onChange={onChangeInput}
        type="number"
      />
      {renderIcon()}
    </ArithmeticAnswerContainer>
  );
};

export default ArithmeticAnswer;
