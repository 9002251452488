import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useDispatch from './useDispatch';
import { actions as studySpaceActions, quizSelectors } from '../StudySpace/redux/slice';
import { actions as applyActions, applySelector } from '../Apply/redux/slice';
import { actions as clickActions, clicksSelector } from '../ClicksQuiz/redux/slice';

const useInitializeAttempts = (isLoggedIn: boolean) => {
  const { onBoardingAttempts, loadingOnBoardingAttempts, placementAttempts, loadingPlacementAttempts } = useSelector(
    quizSelectors.allState,
  );

  const { attempts: applyAttempts, enhancementsAttempts, loading: loadingApply, loadingFetchEnhance } = useSelector(
    applySelector.allState,
  );

  const { attempts: clicksAttempts, loading: loadingClicks } = useSelector(clicksSelector.allState);

  const { dispatch } = useDispatch();

  useEffect(() => {
    if (!isLoggedIn) return;
    if (!onBoardingAttempts && !loadingOnBoardingAttempts) {
      dispatch(studySpaceActions.fetchOnBoardingAttempts());
    }
    if (!placementAttempts && !loadingPlacementAttempts) {
      dispatch(studySpaceActions.fetchPlacementAttempts());
    }

    if ((!applyAttempts || applyAttempts?.length === 0) && !loadingApply) {
      dispatch(applyActions.fetchApplyAttempts());
    }

    if ((!enhancementsAttempts || enhancementsAttempts?.length === 0) && !loadingFetchEnhance) {
      dispatch(applyActions.fetchApplyEnahcnementAttempts());
    }

    if ((!clicksAttempts || clicksAttempts?.length === 0) && !loadingClicks) {
      dispatch(clickActions.fetchClicksAttempts());
    }
  }, [isLoggedIn]);
};

export default useInitializeAttempts;
