import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IQuestion, ILooseObject, IAttempt, IApplyState, TApplyResponse, EApplyTypes, IClicksState } from '../../types';
import { RootState } from '../../redux/store';
import { ISendQuestionResponse, THeaderBannerQuestionResponse } from '../types';

const SLICE_NAME = 'clicks';

const initialState: IClicksState = {
  questionAnswers: {},
  loading: false,
  loadingStartQuiz: false,
  errors: {},
  loadingAnswer: false,
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    attemptQuiz: (state, action: PayloadAction<{ quizId: string; response: ISendQuestionResponse }>) => {
      state.loadingStartQuiz = true;
    },

    attemptQuizSuccess: (state, action: PayloadAction<{}>) => {
      state.loadingStartQuiz = false;
    },
    attemptQuizFailure: (state, action: PayloadAction<{ errors: ILooseObject }>) => {
      state.loadingStartQuiz = false;
      state.errors = action.payload.errors;
    },

    submitAnswer: (
      state,
      action: PayloadAction<{
        attemptId: string;
        response: ISendQuestionResponse;
        storedResponse?: ILooseObject;
      }>,
    ) => {
      state.loadingAnswer = true;
    },

    submitAnswerSuccess: (state, action: PayloadAction<{ response: ILooseObject; questionId: string }>) => {
      const { questionId, response } = action.payload;
      state.questionAnswers = {
        ...state.questionAnswers,
        [questionId]: response,
      };
      state.loadingAnswer = false;
    },

    submitAnswerFailure: (state, action: PayloadAction<{ errors: ILooseObject }>) => {
      state.errors = action.payload.errors;
      state.loading = false;
    },

    fetchClicksAttempts: state => {
      state.loading = true;
    },

    fetchClickAttemptsSuccess: (state, action: PayloadAction<{ attempts: IAttempt[] }>) => {
      const { attempts } = action.payload;
      state.loading = false;
      state.attempts = attempts;
    },

    setQuestionAnswers: (state, action: PayloadAction<{ answers: ILooseObject }>) => {
      state.questionAnswers = action.payload.answers;
    },

    resetState: state => initialState,
  },
});

const clicksSelector = {
  allState: (state: RootState): typeof initialState => state.clicks,
};

const actions = { ...slice.actions };
export { clicksSelector, SLICE_NAME, actions };
export default slice.reducer;
