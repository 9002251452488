import { Dispatch, SetStateAction } from 'react';
import { marked } from 'marked';
import parse from 'html-react-parser';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CustomTooltip from '../../components/Tooltip';
import { MessageContainer, MessageAvatar, MessageText, MessageIcon, UploadMessage, CopyContainer } from '../styled';
import { IMessage } from '../../types';
import { colors } from '../../../themes';
import { ReactComponent as NurseGPTIcon } from '../../../images/nurseGPT.svg';
import { ReactComponent as FileIcon } from '../../../images/file.svg';
import { ReactComponent as CopyIcon } from '../../../images/copy.svg';
import { en } from '../../../i18n';

const { title, you, copy, copied, GPTRole } = en.nurseGPT;

const Message = ({
  message,
  index,
  userIconLetter,
  chat,
  copyToolTipText,
  setCopyTooltipText,
}: {
  message: IMessage;
  index: number;
  userIconLetter: string;
  chat: any;
  copyToolTipText: string;
  setCopyTooltipText: Dispatch<SetStateAction<string>>;
}) => (
  <MessageContainer>
    <MessageAvatar>
      {message.source === 'user' ? (
        <>
          <MessageIcon backgroundColor={colors.purple30}>{userIconLetter}</MessageIcon>
          {you}
        </>
      ) : (
        <>
          <MessageIcon backgroundColor={colors.white}>
            <NurseGPTIcon />
          </MessageIcon>
          {GPTRole}
        </>
      )}
    </MessageAvatar>
    {message.content.startsWith('Uploaded File:') ? (
      <UploadMessage>
        <div>
          <FileIcon />
        </div>
        <div>
          <div>{message.content.slice('Uploaded File: '.length)}</div>
          <div>{message.content.split('.').pop()?.toUpperCase()}</div>
        </div>
      </UploadMessage>
    ) : (
      <>
        <MessageText>
          <div>{parse(marked.parse(message.content.replace(/(\【.*?\】)/g, '')) as string)}</div>
        </MessageText>
        {message.source === 'assistant' && (
          <CopyContainer isLastMessage={index === chat.length - 1}>
            <CopyToClipboard
              text={message.content.replace(/(\【.*?\】)/g, '')}
              onCopy={() => setCopyTooltipText(copied)}
            >
              <CustomTooltip
                centerText
                arrow={false}
                title={copyToolTipText}
                width="fit-content"
                padding="8px 16px"
                enterTouchDelay={0}
                onClose={() => setTimeout(() => setCopyTooltipText(copy), 200)}
              >
                <span>
                  <CopyIcon />
                </span>
              </CustomTooltip>
            </CopyToClipboard>
          </CopyContainer>
        )}
      </>
    )}
  </MessageContainer>
);

export default Message;
