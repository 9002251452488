import { MutableRefObject, KeyboardEvent, ChangeEvent } from 'react';
import { Input } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Spinner from '../../components/Spinner';
import { ReactComponent as UploadIcon } from '../../../images/nursegpt-upload.svg';
import { ReactComponent as FileIcon } from '../../../images/file.svg';
import { ReactComponent as ErrorIcon } from '../../../images/icons/error.svg';
import { SubmitButtonContainer, DisclaimerText, UploadedFileName, UploadError, ConnectionError } from '../styled';
import { en } from '../../../i18n';
import { IFileUpload, ILooseObject } from '../../types';

const {
  disclaimer,
  retryUpload,
  initialPlaceholder,
  initialMobilePlaceholder,
  ongoingPlaceholder,
  ongoingMobilePlaceholder,
  connectionLost,
} = en.nurseGPT;

const PromptInput = ({
  prompt,
  handlePromptChange,
  onKeyDown,
  inputRef,
  getPlaceholder,
  handlePromptSubmit,
  currentFile,
  loadingResponse,
  fileUpload,
  fileInputKey,
  onFileChange,
  cancelFileUpload,
  errors,
  disableUpload,
  toggleUploadModal,
  isReconnecting,
}: {
  prompt: string;
  handlePromptChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
  inputRef: MutableRefObject<HTMLTextAreaElement>;
  getPlaceholder: (
    initialPlaceholder: string,
    ongoingPlaceholder: string,
    initialMobilePlaceholder: string,
    ongoingMobilePlaceholder: string,
  ) => string;
  handlePromptSubmit: () => void;
  loadingResponse: boolean;
  currentFile?: File;
  fileUpload?: IFileUpload | null;
  fileInputKey?: number;
  onFileChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  cancelFileUpload?: () => void;
  errors: ILooseObject;
  disableUpload?: boolean;
  toggleUploadModal?: () => void;
  isReconnecting?: boolean;
}) => {
  return (
    <div>
      <Input
        maxRows={20}
        placeholder={getPlaceholder(
          initialPlaceholder,
          ongoingPlaceholder,
          initialMobilePlaceholder,
          ongoingMobilePlaceholder,
        )}
        onChange={handlePromptChange}
        value={prompt}
        disableUnderline
        fullWidth
        multiline
        onKeyDown={onKeyDown}
        inputRef={inputRef}
      />
      <SubmitButtonContainer>
        <DisclaimerText>{disclaimer}</DisclaimerText>
        <InputAdornment position="end">
          <IconButton
            onClick={handlePromptSubmit}
            edge="end"
            disabled={(!prompt && !currentFile) || loadingResponse || fileUpload?.failed}
          >
            <ArrowUpwardIcon />
          </IconButton>
        </InputAdornment>
        <InputAdornment position="start">
          <label htmlFor="nursegpt-file-upload">
            <IconButton component="span" edge="start" disabled={loadingResponse} onClick={toggleUploadModal}>
              <UploadIcon />
            </IconButton>
          </label>
          <input
            key={fileInputKey}
            onChange={onFileChange}
            accept=".pdf,.txt,.docx"
            id="nursegpt-file-upload"
            type="file"
            disabled={loadingResponse || disableUpload}
          />
        </InputAdornment>
        {currentFile && !fileUpload?.failed && (
          <UploadedFileName>
            <FileIcon />
            {currentFile.name.length > 20 ? `${currentFile.name.slice(0, 21)}...` : currentFile.name}
            {loadingResponse && <Spinner loading={loadingResponse} inline size="20px" />}
            <button onClick={cancelFileUpload}>X</button>
          </UploadedFileName>
        )}
        {currentFile && fileUpload?.failed && (
          <UploadError>
            <ErrorIcon />
            {errors.message || retryUpload}
            <button onClick={cancelFileUpload}>X</button>
          </UploadError>
        )}
        {isReconnecting && (
          <ConnectionError>
            {connectionLost}
            <Spinner inline loading />
          </ConnectionError>
        )}
      </SubmitButtonContainer>
    </div>
  );
};

export default PromptInput;
