import { IStepComponent } from '../types';
import { ChoicesContainer, SmallChoicesContainer } from './styled';
import useStep from './useStep';

const PatientPopulation = ({ question }: IStepComponent) => {
  const { title, renderMultipleChoices, onChangeAnswer } = useStep({ question });
  return (
    <>
      {title}
      <SmallChoicesContainer>{renderMultipleChoices('checkbox')}</SmallChoicesContainer>
    </>
  );
};

export default PatientPopulation;
