import { Body, Container, FullContainer, SubTitle, SvgContainer, TipsHeader } from './styled';
import { ReactComponent as CloseIcon } from '../../../../images/icons/nurseGPT/helpfulTipsClose.svg';
import { en } from '../../../../i18n';
import CustomAccordion from './Accordion';

const {
  nurseGPT: { tipsPopup },
} = en;
interface ITips {
  isHidden?: boolean;
  close: () => void;
}

const Tips = ({ isHidden, close }: ITips) => {
  return (
    <FullContainer isHidden={isHidden}>
      <SvgContainer>{!isHidden && <CloseIcon onClick={close} />}</SvgContainer>
      <Container>
        <Body>
          <TipsHeader>{tipsPopup.title}</TipsHeader>
          <SubTitle>{tipsPopup.subTitle}</SubTitle>

          <CustomAccordion />
        </Body>
      </Container>
    </FullContainer>
  );
};

export default Tips;
