import { call, put, takeLatest, delay, cancel, fork, take, takeEvery, cancelled } from 'redux-saga/effects';
import { SagaIterator, Task } from 'redux-saga';
import { actions } from './slice';
import axiosRequest, { ERequestMethods, getCancelTokenSource } from '../../axios';
import {
  IFetchAttemptResponse,
  IAttempt,
  IStartQuizResponse,
  IAnswerQuestionResponse,
  ISubmitPromptResponse,
  IFetchSessionsResponse,
  IFetchSessionResponse,
  ILooseObject,
  IUploadResponse,
  IFileUpload,
} from '../../types';
import { endpoints, NURSE_GPT_QUIZ_ID } from '../../constants';
import { QuestionResponseTransformer, FullAttemptsTransformer, GoogleTags, CurrentProfile } from '../../classes';
import { pollRequest } from '../../utils';
import { SendJsonMessage } from 'react-use-websocket/src/lib/types';
import { en } from '../../../i18n';

function* attemptQuiz() {
  try {
    const { data: attempts } = yield call(axiosRequest, {
      url: `${endpoints.fetchAttempts}&quizId=${NURSE_GPT_QUIZ_ID}`,
    });
    let attempt: IAttempt = attempts[0];
    if (!attempt) {
      const { data: result }: IStartQuizResponse = yield call(pollRequest, {
        url: endpoints.startQuiz,
        requestData: { body: { quizId: NURSE_GPT_QUIZ_ID } },
        method: ERequestMethods.POST,
      });
      const attemptId = result;
      if (!attemptId) throw new Error('Could not start quiz');
      const stoppingCriteria = (apiAttempt: any) => {
        return apiAttempt.data.attemptId === attemptId;
      };
      const { data: newAttempt }: IFetchAttemptResponse = yield call(
        pollRequest,
        {
          url: endpoints.fetchAttempt(attemptId),
        },
        { stopCallBack: stoppingCriteria },
      );
      attempt = newAttempt;
    }
    const attemptsTransformer = new FullAttemptsTransformer();
    const transformedAttempt: IAttempt = yield call([attemptsTransformer, attemptsTransformer.transform], attempt);
    const { questionsTransformed } = transformedAttempt;
    const submitted = !!attempt.questions[0].response;
    yield put(actions.attemptQuizSuccess({ attempt, questions: questionsTransformed, submitted }));
  } catch (e) {
    yield put(actions.attemptQuizFailure({ errors: { message: e } }));
  }
}

function* submitAnswer(action: { payload: { questionId: string; attemptId: string; response: string } }) {
  try {
    const { payload } = action;
    const { questionId, response, attemptId } = payload;
    const questionResponseTransformer = new QuestionResponseTransformer();
    const transformedResponse = questionResponseTransformer.transformOut(
      { questionId, response: response || 'Joined without response' },
      true,
    );
    const { data }: IAnswerQuestionResponse = yield call(axiosRequest, {
      url: endpoints.submitAnswer(attemptId),
      requestData: {
        body: transformedResponse,
      },
      method: ERequestMethods.POST,
    });
    yield put(actions.submitAnswerSuccess());
  } catch (error) {
    yield put(actions.submitAnswerFailure({ errors: { message: error } }));
  }
}

function* submitPrompt(action: {
  payload: {
    prompt: string;
    currentSessionId: string;
    sendJsonMessage: SendJsonMessage;
    fileUpload: IFileUpload | null;
  };
}) {
  try {
    const {
      payload: { prompt, currentSessionId, sendJsonMessage, fileUpload },
    } = action;
    yield call(sendJsonMessage, {
      action: 'stream_v2',
      prompt,
      httpMethod:
        currentSessionId || fileUpload?.fileName
          ? ERequestMethods.PUT.toUpperCase()
          : ERequestMethods.POST.toUpperCase(),
      ...((currentSessionId || fileUpload?.fileName) && { session_id: currentSessionId || fileUpload?.sessionId }),
      ...(fileUpload?.fileId && { uploaded_file: fileUpload.fileId }),
    });
  } catch (error) {
    yield put(actions.submitPromptFailure({ errors: { webSocketError: { message: error } } }));
  }
}

function* fetchSessions(action: { payload: { lastSession?: ILooseObject } }) {
  try {
    const { lastSession } = action.payload;
    const { data }: { data: IFetchSessionsResponse[] } = yield call(axiosRequest, {
      url: `${endpoints.nurseGPT}?limit=20${
        lastSession ? `&after_id=${lastSession.sessionId}&after_created_at=${lastSession.createdAt / 1000}` : ''
      }`,
    });
    yield put(actions.fetchSessionsSuccess({ sessions: data }));
  } catch (error) {
    yield put(actions.fetchSessionsFailure({ errors: { message: error } }));
  }
}

function* fetchSession(action: { payload: { session: ILooseObject } }) {
  const { session } = action.payload;
  try {
    const { data }: { data: IFetchSessionResponse[] } = yield call(axiosRequest, {
      url: `${endpoints.nurseGPT}?session_id=${session.sessionId}&limit=10${
        session.messages?.length > 0 ? `&after=${session.messages[0].messageId}` : ''
      }`,
    });
    yield put(actions.fetchSessionSuccess({ session: data[0] }));
  } catch (error) {
    yield put(actions.fetchSessionFailure({ errors: { message: error }, session }));
  }
}

function* deleteSession(action: { payload: { sessionId: string } }) {
  try {
    const {
      payload: { sessionId },
    } = action;
    yield call(axiosRequest, {
      url: `${endpoints.nurseGPT}?session_id=${sessionId}`,
      method: ERequestMethods.DELETE,
    });
    yield put(actions.deleteSessionVisualSuccess({ sessionId }));
    yield delay(400);
    yield put(actions.deleteSessionSuccess({ sessionId }));
  } catch (error) {
    yield put(actions.deleteSessionFailure({ errors: { message: error } }));
  }
}

function* renameSession(action: { payload: { sessionId: string; title: string; originalTitle: string } }) {
  try {
    const {
      payload: { sessionId, title },
    } = action;
    yield call(axiosRequest, {
      url: `${endpoints.nurseGPT}`,
      requestData: {
        body: {
          session_id: sessionId,
          session_title: title,
        },
      },
      method: ERequestMethods.PUT,
    });
    yield put(actions.renameSessionSuccess());
  } catch (error) {
    const {
      payload: { sessionId, originalTitle },
    } = action;
    yield put(actions.renameSessionFailure({ errors: { message: error }, sessionId, originalTitle }));
  }
}

function* upload(action: { payload: { file: File; currentSessionId: string } }): Generator<any, void, any> {
  const cancelTokenSource = getCancelTokenSource();
  try {
    const {
      payload: { file, currentSessionId },
    } = action;
    const {
      data: { presignedUrl, fields, sessionId, fileId },
    }: { data: IUploadResponse } = yield call(axiosRequest, {
      url: `${endpoints.nurseGPT}/upload`,
      requestData: {
        body: {
          ...(currentSessionId && { session_id: currentSessionId }),
          file_name: file.name,
        },
      },
      method: ERequestMethods.POST,
      cancelToken: cancelTokenSource.token,
    });

    yield put(actions.setUploadFileKey({ fileKey: fields.key, fileId }));

    const formData = new FormData();
    Object.entries(fields).forEach(([field, value]) => formData.append(field, `${value}`));
    formData.append('Content-Type', file?.type);
    formData.append('file', file);

    yield call(axiosRequest, {
      url: presignedUrl,
      isJson: false,
      requestData: { body: formData },
      method: ERequestMethods.POST,
      useAuthorization: false,
      cancelToken: cancelTokenSource.token,
    });

    const stoppingCriteria = (data: any) => {
      return Boolean(data.data.file_details);
    };

    const result = yield call(
      pollRequest,
      {
        url: `${endpoints.nurseGPT}?file_id=${fileId}`,
        cancelToken: cancelTokenSource.token,
      },
      { stopCallBack: stoppingCriteria, pollingAttempts: 20, useStopCallBackOnError: true },
    );

    if (result?.data?.file_details) {
      if (!result.data.failed) yield put(actions.uploadSuccess({ sessionId }));
      else throw Error(result.data.failure_reason);
    } else throw Error(en.nurseGPT.retryUpload);
  } catch (error: any) {
    yield put(actions.uploadFailure({ errors: { message: error.message } }));
  } finally {
    if (yield cancelled()) cancelTokenSource.cancel();
  }
}

function* deleteFile(action: { payload: { fileKey: string } }) {
  try {
    const {
      payload: { fileKey },
    } = action;
    yield call(axiosRequest, {
      url: `${endpoints.nurseGPT}?file_key=${fileKey}`,
      method: ERequestMethods.DELETE,
    });
    yield put(actions.deleteFileSuccess());
  } catch (error) {
    yield put(actions.deleteFileFailure({ errors: { message: error } }));
  }
}

function* deleteFileOnRefresh(action: { payload: { fileKey: string } }) {
  const {
    payload: { fileKey },
  } = action;
  const token = CurrentProfile.getToken();
  yield call(fetch, `${endpoints.nurseGPT}?file_key=${fileKey}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    keepalive: true,
  });
}

function* cancelSaga(saga: Task) {
  yield cancel(saga);
}

function* watchAttemptQuiz() {
  yield takeLatest(actions.attemptQuiz, attemptQuiz);
}

function* watchSubmitAnswer() {
  yield takeLatest(actions.submitAnswer, submitAnswer);
}

function* watchSubmitPrompt() {
  yield takeLatest(actions.submitPrompt, submitPrompt);
}

function* watchSubmitErrorPrompt() {
  yield takeLatest(actions.submitErrorPrompt, submitPrompt);
}

function* watchFetchSessions() {
  yield takeLatest(actions.fetchSessions, fetchSessions);
}

function* watchFetchSession() {
  yield takeLatest(actions.fetchSession, fetchSession);
}

function* watchDeleteSession() {
  yield takeLatest(actions.deleteSession, deleteSession);
}

function* watchRenameSession() {
  yield takeLatest(actions.renameSession, renameSession);
}

function* watchUpload(): SagaIterator {
  while (true) {
    const uploadTask = yield fork(upload, yield take(actions.upload));
    yield takeEvery(actions.cancelUpload, cancelSaga, uploadTask);
  }
}

function* watchDeleteFile() {
  yield takeLatest(actions.deleteFile, deleteFile);
}

function* watchDeleteFileOnRefresh() {
  yield takeLatest(actions.deleteFileOnRefresh, deleteFileOnRefresh);
}

const sagas = [
  watchAttemptQuiz,
  watchSubmitAnswer,
  watchSubmitPrompt,
  watchFetchSessions,
  watchFetchSession,
  watchDeleteSession,
  watchRenameSession,
  watchUpload,
  watchDeleteFile,
  watchSubmitErrorPrompt,
  watchDeleteFileOnRefresh,
];

export default sagas;
