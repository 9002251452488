import { MutableRefObject } from 'react';
import { marked } from 'marked';
import parse from 'html-react-parser';
import { MessageContainer, MessageAvatar, MessageText, MessageIcon } from '../styled';
import { ReactComponent as NurseGPTIcon } from '../../../images/nurseGPT.svg';
import { ILooseObject } from '../../types';
import { colors } from '../../../themes';
import { en } from '../../../i18n';

const { GPTRole } = en.nurseGPT;

const TypedResponse = ({
  loadingResponse,
  currentSession,
  typedResponseRef,
  typedResponse,
  svgLoader,
}: {
  loadingResponse: boolean;
  currentSession: ILooseObject | undefined;
  typedResponseRef: MutableRefObject<HTMLDivElement>;
  typedResponse: string;
  svgLoader: string;
}) => {
  if (!loadingResponse || !currentSession?.receivingResponse) return null;
  return (
    <MessageContainer>
      <MessageAvatar>
        <MessageIcon backgroundColor={colors.white}>
          <NurseGPTIcon />
        </MessageIcon>
        {GPTRole}
      </MessageAvatar>
      <MessageText>
        <div ref={typedResponseRef}>
          {parse(marked.parse(`${typedResponse.replace(/(\【.*?\】)/g, '')} ${svgLoader}`) as string)}
        </div>
      </MessageText>
    </MessageContainer>
  );
};

export default TypedResponse;
