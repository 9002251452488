import { MenuItem, OutlinedInput, styled } from '@mui/material';
import { colors } from '../../../themes';
import { shouldForwardProps } from '../../utils';
import { ListContainer, ListDetail, ListItem } from '../../OnBoarding/Ready/styled';
import { QuizCard } from '../../Home/PracticeQuiz/styled';

interface ITitle {
  centerMobile?: boolean;
  normalFontWeight?: boolean;
  fontSize?: string;
  applyBottomMargin?: boolean;
}

interface ITextNumberInput {
  isVisible?: boolean;
}

const Title = styled('h1', {
  shouldForwardProp: prop =>
    shouldForwardProps(prop, ['centerMobile', ' normalFontWeight', 'fontSize', 'applyBottomMargin']),
})<ITitle>(
  ({ theme, centerMobile, normalFontWeight, fontSize, applyBottomMargin }) => `
  font-size: ${fontSize || '1.5rem'};
  font-weight: ${normalFontWeight ? 400 : 700};
  text-align: center;
  .purple {
    color: ${colors.primary};
  }
  * {
    font-size: ${fontSize || '1.5rem'};
    font-weight: ${normalFontWeight ? 400 : 700};
  }
  ${theme.breakpoints.down('sm')} {
    text-align: ${centerMobile ? 'center' : 'left'};
  }

  ${applyBottomMargin ? 'margin-bottom:32px;' : ''}
`,
);

const QuestionHeader = styled('div')`
  font-size: 1.25rem;
  width: 80%;

  margin-left: auto;
  margin-right: auto;
  ${props => props.theme.breakpoints.down('sm')} {
    width: auto;
  }
`;

const InputContainer = styled('div')`
  width: 60%;

  ${props => props.theme.breakpoints.down('$lg')} {
    width: 80%
}
  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
}
  input,
  * {
    width: 100%;
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
    display: none;

  }
  ${props => props.theme.breakpoints.down('sm')} {

    input,
    * {
       width:auto;
    }

    .MuiFormControl-root {
       width:100%;
    }
  }



`;

const MatrixInputContainer = styled('div')`
  width: 75%;
  ${props => props.theme.breakpoints.down('lg')} {
    width: 100%;
  }
  margin-bottom: 80px;
  ${props => props.theme.breakpoints.down('md')} {
    margin-bottom: 32px;
  }
`;

const QuestionHeaderCentered = styled(QuestionHeader)`
  font-size: 1rem;
  width: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
`;

const ChoiceContainer = styled('div')`
  display: flex;
  gap: 5px;
`;

const ChoicesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SmallChoicesContainer = styled(ChoicesContainer)`
  p {
    font-size: 1rem;
  }
`;
const ChoicesContainerFullWidth = styled(ChoicesContainer)`
  width: 60%;
  ${props => props.theme.breakpoints.down('xmd')} {
    width: 100%;
  }
`;

const DropDownTitleContainer = styled('div')`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 1rem;
    text-align: left;
  }
`;

const LocationInputContainer = styled('div')`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  ${props => props.theme.breakpoints.down('$lg')} {
    width: 80%;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
  input,
  div
  {
    width: 100%;
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
    display: none;

  }
  svg,img {
    width:auto;
  }
  ${props => props.theme.breakpoints.down('sm')} {

 

    .MuiFormControl-root {
       width:100%;
    }
  }

`;

const CountryMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const ChoiceContainerCommute = styled('div')`
  display: flex;
  cursor: pointer;
  width: 100%;
  * {
    font-weight: 400;
    font-size: 1.3rem;
  }
`;

const ChoicesContainerCommute = styled('div')`
  display: flex;
  flex-direction: column;
  width: 50%;
  ${props => props.theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

const ChoicesContainerWorkEnv = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    font-size: 1rem;
  }
`;

const ChoicesContainerNursingRoles = styled('div')`
  p {
    font-size: 1rem;
  }
  display: flex;
  gap: 32px;
  div {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  span {
    padding-bottom: 0;
  }
`;

const NursingRolesContent = styled('div')`
  display: flex;
  align-items: end;
  gap: 64px;
  ${props => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    gap: 32px;
  }
`;

const NursingRolesDisclaimer = styled('div')`
  display: none;
  ${props => props.theme.breakpoints.down('md')} {
    display: block;
  }
`;

const DesktopLicenseContainer = styled('div')`
  display: flex;
  gap: 32px;
`;

const LicenseColumnContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ColumnTitle = styled('h1')`
  font-size: 1rem;
  margin: 0;
  text-align: left;
`;

const LicenseInputContainer = styled('div')`
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position:relative;
  margin-bottom:88px;
  ${props => props.theme.breakpoints.down('$lg')} {
    width: 80%;
  }
  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
  input,
  div {
    width:100%;
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
    display: none;

  }
  svg,img {
    width:auto;
  }
  ${props => props.theme.breakpoints.down('sm')} {

 

    .MuiFormControl-root {
       width:100%;
    }
  }
`;

const LicenseDisclaimer = styled('div')`
  position: absolute;
  font-size: 1rem;
  bottom: -120px;

  ${props => props.theme.breakpoints.down('sm')} {
    position: static;
    text-align: center;
    margin-top: 32px;
  }
`;

const AttachmentButtonContainer = styled('div')`
  margin-top: 64px;
`;

const AttachmentButtonContainerBottomMargin = styled(AttachmentButtonContainer)`
  margin-bottom: 64px;
`;

const TextNumberInput = styled(OutlinedInput, {
  shouldForwardProp: prop => shouldForwardProps(prop, ['centerMobile']),
})<ITextNumberInput>(
  ({ theme, isVisible }) => `
  position: absolute;
  bottom: -25px;

  width: auto;
  max-width:${isVisible ? '100%' : '0px'};
  transition: max-width 1.5s linear;
  overflow:hidden;

`,
);

const ChoiceInputContiner = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

const ListColumnItemOverride = styled(ListItem)`
  width: 50%;
  cursor: pointer;
`;

const ListContainerOverride = styled(ListContainer)`
  width: 70%;
  margin-bottom: 128px;

  ${props => props.theme.breakpoints.down('md')} {
    width: auto;
    margin-bottom: 64px;
  }
`;

const ButtonContainer = styled('div')`
  margin-bottom: 16px;
  display: flex;
  gap: 32px;
  cursor: pointer;
  flex-direction: column;
  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

const FAQContainer = styled('div')`
  margin-top: auto;
  margin-bottom: 48px;
`;

const ExitCardsContainer = styled('div')`
  display: flex;
  gap: 16px;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  margin-bottom: 32px;
  &::first-child {
    width: 100%;
  }
`;

const QuestionSubHeader = styled('div')`
  width: 100%;
  font-size: 1.3rem;
  margin-bottom: 32px;
  ${props => props.theme.breakpoints.down('sm')} {
    width: auto;
  }
`;

const NursingRolesText = styled('div')`
  ${props => props.theme.breakpoints.down('md')} {
    display: none;
  }
`;

// const SkillsChoicesContainer = styled('div')`
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   gap: 0px;
//   width: 80%;
//   margin: 0 auto;
//   justify-items: center;
//   font-size: 1rem;
//   p,
//   div {
//     font-size: 1rem;
//     margin: 0;
//   }

//   .choice {
//     display: flex;
//     justify-content: flex-start; /* Align text to the left within each item */
//     align-items: center;
//   }
// `;

const SkillsChoicesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  margin-left: 35px;
  p,
  div {
    font-size: 1rem;
    margin: 0;
  }

  ${props => props.theme.breakpoints.down('md')} {
    margin-left: 0px;
  }
`;

const SkillChoiceRow = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 64px;
  align-items: center;
  * {
    height: 100%;
    align-items: center;
  }

  p:nth-of-type(odd) {
    min-width: 300px;
  }

  ${props => props.theme.breakpoints.down('md')} {
    p:nth-of-type(odd) {
      min-width: 0px;
    }
  }
  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    gap: 0px;
    p:nth-of-type(odd) {
      min-width: 0px;
    }
  }
`;

const ExitQuizCard = styled(QuizCard)`
  padding-top: 19px;
  padding-bottom: 19px;
  padding-left: 32px;
  padding-right: 32px;
  min-height: 0;
  ${props => props.theme.breakpoints.down('xsm')} {
    padding: 12px;
    padding-top: 19px;
    padding-bottom: 19px;
    * {
      text-align: center;
    }
  }
  p {
    text-decoration: none;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    .button-container {
      text-align: center;
    }
  }
`;

const ExitCardButtonContainer = styled('div')`
  span {
    color: ${colors.primary};
    font-weight: 700;
  }

  ${props => props.theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

export {
  Title,
  QuestionHeader,
  InputContainer,
  QuestionHeaderCentered,
  ChoiceContainer,
  ChoicesContainer,
  DropDownTitleContainer,
  LocationInputContainer,
  CountryMenuItem,
  ChoiceContainerCommute,
  ChoicesContainerCommute,
  DesktopLicenseContainer,
  LicenseColumnContainer,
  ColumnTitle,
  LicenseInputContainer,
  LicenseDisclaimer,
  AttachmentButtonContainer,
  AttachmentButtonContainerBottomMargin,
  ChoicesContainerFullWidth,
  TextNumberInput,
  ChoiceInputContiner,
  MatrixInputContainer,
  ListColumnItemOverride,
  ListContainerOverride,
  ButtonContainer,
  FAQContainer,
  ExitCardsContainer,
  ChoicesContainerWorkEnv,
  QuestionSubHeader,
  ChoicesContainerNursingRoles,
  NursingRolesContent,
  NursingRolesText,
  NursingRolesDisclaimer,
  SkillsChoicesContainer,
  SkillChoiceRow,
  SmallChoicesContainer,
  ExitQuizCard,
  ExitCardButtonContainer,
};
